import { unref } from "vue";
import { debounce as _debounce } from "throttle-debounce";

export function clone(src: any): any {
  const safeSrc = unref(src);
  return JSON.parse(JSON.stringify(safeSrc));
}

export function comapareJson(src: any, trg: any): boolean {
  return JSON.stringify(src) === JSON.stringify(trg);
}

export function debounce(
  func: (..._args: any[]) => any,
  _options: { delay?: number; atBegin?: boolean } = {}
) {
  const { delay, atBegin } = Object.assign(
    { delay: 1000, atBegin: false },
    _options
  );
  return _debounce(delay, atBegin, func);
}
