
import {
  defineComponent,
  SetupContext,
  computed,
  reactive,
  toRefs,
  unref,
  watch,
  PropType,
} from "vue";
import { underscore } from "inflected";
import {
  useGetAllResources,
  useCreateResource,
  useUpdateResource,
  useRemoveResource,
  useImportResource,
  useExportResource,
} from "../../modules";
import {
  clone,
  Resource,
  VariableDefs,
  Pagination,
  Attr,
  Attrs,
  EditMode,
} from "../../core";
import { AttrEditor, AttrViewer } from "../attrs";
import ModelForm from "../entry/Form.vue";
import Dragable from "../etc/Dragable.vue";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  // ReloadOutlined,
  DownloadOutlined,
  UploadOutlined,
  MoreOutlined,
  FilterOutlined,
  RightSquareOutlined,
  DownSquareOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

type Props = {
  editMode: EditMode;
  rowKey: string;
  name: string;
  attrs: Attr[];
  pagination: boolean;
  alwaysShowAttrs: string[];
  rowDraggable: boolean;
  columnResizable: boolean;
  orderAttr: string;
  defaultValue: Record<string, any>;
  creatable: boolean;
  editable: boolean;
  removable: boolean;
  exportable: boolean;
  importable: boolean;
  queryVariables: VariableDefs;
  createMutationVariables: VariableDefs;
  childrenColumnName: string | null;
};
import { useI18n } from "vue-i18n";

const defaultPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
};

// const fileFormats = ["csv", "xlsx"]; // エクセルファイルのエクスポートは未対応
const fileFormats = ["csv"];
// const pageSizeOptions = [10, 15, 20, 25, 50, 100];

export const GenericList = defineComponent({
  components: {
    AttrEditor,
    AttrViewer,
    ModelForm,
    Dragable,
    CheckCircleOutlined,
    CloseCircleOutlined,
    // ReloadOutlined,
    DownloadOutlined,
    UploadOutlined,
    MoreOutlined,
    FilterOutlined,
    RightSquareOutlined,
    DownSquareOutlined,
  },
  props: {
    editMode: {
      type: String as PropType<EditMode>,
      required: false,
      default: "none",
    },
    rowKey: {
      type: String,
      required: false,
      default: () => "id",
    },
    name: {
      type: String,
      required: true,
    },
    attrs: {
      type: Array as PropType<Attrs>,
      required: true,
      default: () => [],
    },
    pagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    alwaysShowAttrs: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => ["id", "name"],
    },
    rowDraggable: {
      type: Boolean,
      required: false,
      default: true,
    },
    columnResizable: {
      type: Boolean,
      required: false,
      default: true,
    },
    orderAttr: {
      type: String,
      required: false,
      default: "viewOrder",
    },
    defaultValue: {
      type: Object as PropType<Record<string, any>>,
      required: false,
      default: () => ({}),
    },
    creatable: {
      type: Boolean,
      required: false,
      default: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    removable: {
      type: Boolean,
      required: false,
      default: true,
    },
    exportable: {
      type: Boolean,
      required: false,
      default: true,
    },
    importable: {
      type: Boolean,
      required: false,
      default: true,
    },
    queryVariables: {
      type: Array as PropType<VariableDefs>,
      required: false,
      default: [] as VariableDefs,
    },
    createMutationVariables: {
      type: Array as PropType<VariableDefs>,
      required: false,
      default: [] as VariableDefs,
    },
    childrenColumnName: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ["created", "updated", "removed"],
  setup(props: Props, context: SetupContext) {
    const { t } = useI18n();

    const initVisibleAttrs = () => {
      const visibleAttrs = {} as { [key: string]: boolean };
      props.attrs.forEach((attr: Attr) => {
        visibleAttrs[attr.key] = true;
      });
      return visibleAttrs;
    };

    // ページネーション
    const initPaginationObject = (): any => {
      if (props.pagination == false) {
        return false;
      } else {
        return clone(defaultPagination);
      }
    };

    const state = reactive({
      visibleAttrs: initVisibleAttrs() as { [key: string]: boolean },
      currentResource: null as Resource | null,
      errorMessages: {} as { [key: string]: string } | null,
      paginationObject: initPaginationObject() as Pagination | false,
    });

    const paginationVariables = computed((): VariableDefs => {
      if (state.paginationObject == false) {
        return [];
      } else {
        const { pageSize, current } = state.paginationObject;
        if (current == null) return [];
        return [
          {
            key: "offset",
            graphQLType: "Int",
            value: (current - 1) * pageSize,
          },
          { key: "limit", graphQLType: "Int", value: pageSize },
        ];
      }
    });

    const queryExtendVariables = computed(
      (): VariableDefs =>
        [...props.queryVariables, ...unref(paginationVariables)] as VariableDefs
    );

    const createMutaionExtendVariables = computed(
      (): VariableDefs => [...props.createMutationVariables] as VariableDefs
    );

    const updateMutaionExtendVariables = computed(
      (): VariableDefs =>
        props.rowDraggable
          ? ([{ key: props.orderAttr, graphQLType: "Int" }] as VariableDefs)
          : ([] as VariableDefs)
    );

    const resourceAttrs = computed(
      () =>
        [
          {
            key: "id",
            type: "id",
          } as Attr,
          ...props.attrs,
        ] as Attr[]
    );

    const { resources, refetch, total, loading } = useGetAllResources(
      props.name,
      resourceAttrs,
      queryExtendVariables,
      props.childrenColumnName
    );

    const { create, errorMessages: createErrorMessages } = useCreateResource(
      props.name,
      resourceAttrs,
      createMutaionExtendVariables
    );
    const { update, errorMessages: updateErrorMessages } = useUpdateResource(
      props.name,
      resourceAttrs,
      updateMutaionExtendVariables
    );
    const { remove, errorMessages: removeErrorMessages } = useRemoveResource(
      props.name,
      resourceAttrs
    );

    const { importData, errorMessages: importErrorMessages } =
      useImportResource(props.name);

    const { exportData, errorMessages: exportErrorMessages } =
      useExportResource(props.name);

    // const draggable = computed((): boolean => props.rowDraggable); // ドラッグ未対応のため一時対応
    const draggable = false;
    const inlineEditMode = computed((): boolean => props.editMode === "inline");
    const drawerEditMode = computed((): boolean => props.editMode === "drawer");

    const isOpenModal = computed(
      (): boolean =>
        state.currentResource != null &&
        (state.currentResource.id == null || unref(drawerEditMode))
    );

    const editableResourceAttrs = computed((): Attr[] =>
      props.attrs.filter((attr: Attr) => attr.key !== "id" || !attr.readonly)
    );

    const fields = computed((): any[] =>
      props.attrs.filter(
        (attr: Attr) => !props.alwaysShowAttrs.includes(attr.key)
      )
    );

    watch(total, () => {
      if (state.paginationObject !== false) {
        state.paginationObject.total = unref(total) as unknown as number;
      }
    });

    const getColmunTitle = (attr: Attr) => {
      if (attr.title != null) {
        return attr.title;
      } else if (attr.titleKey != null) {
        return t(`attributes.${underscore(attr.titleKey)}`);
      } else {
        return t(`attributes.${underscore(attr.key)}`);
      }
    };

    const columns = computed((): any[] => {
      if (props.attrs == null) return [];

      let _columns: any = [];
      // ドラッグ用のカラム
      if (props.rowDraggable) {
        _columns.push({
          key: "dragHandle",
          width: 40,
          slots: { customRender: "dragHandle" },
        });
      }

      // カラムデータ
      _columns = _columns.concat(
        props.attrs
          .filter(
            (attr: Attr) =>
              state.visibleAttrs[attr.key] && attr.visible !== false
          )
          .map((attr: Attr) => {
            return {
              title: getColmunTitle(attr),
              key: attr.key,
              dataIndex: attr.key,
              width: attr.width,
              align: attr.align,
              slots: { customRender: attr.key },
              sorter: attr.sorter,
              // sortDirections: attr.sortDirections,
              // defaultSortOrder: attr.defaultSortOrder,
            };
          })
      );

      // アクション用にカラム
      _columns.push({
        title: "アクション",
        width: "120",
        fixed: "right",
        slots: { customRender: "action" },
      });

      return _columns;
    });

    const handleRefetch = () => {
      refetch();
    };

    const handleDragged = async (
      draggingItem: any,
      draggedItem: any,
      dragedUpper: boolean,
      dataSource: Resource[]
    ) => {
      const draggingItemIdx = dataSource.indexOf(draggingItem);
      const draggedItemIdx = dataSource.indexOf(draggedItem);

      const target = clone(draggingItem);

      if (dragedUpper) {
        if (draggingItemIdx + 1 === draggedItemIdx) {
          return;
        }
        target[props.orderAttr] = draggedItemIdx;
      } else {
        if (draggingItemIdx > draggedItemIdx) {
          target[props.orderAttr] = draggedItemIdx + 1;
        } else {
          target[props.orderAttr] = draggedItemIdx;
        }
      }

      await update(target);
    };

    const handleAdd = () => {
      state.errorMessages = null;
      state.currentResource = clone(props.defaultValue);
    };

    const handleEdit = (resource: any) => {
      state.currentResource = clone(resource);
    };

    const handleCancel = () => {
      state.errorMessages = null;
      state.currentResource = null;
    };

    const handleSave = async (newResource: any) => {
      const resource =
        newResource != null ? newResource : state.currentResource;
      if (resource == null) {
        state.currentResource = null;
        return;
      } else {
        if (resource.id === undefined) {
          try {
            const result = await create(resource);
            if (result) {
              await refetch();
              message.success(`作成しました。`);
              handleCancel();
              context.emit("created", resource);

              state.currentResource = null;
            } else {
              state.errorMessages = unref(createErrorMessages);
              if (unref(inlineEditMode) && state.errorMessages != null) {
                message.error(
                  `作成できませんでした。(${Object.values(state.errorMessages)
                    .map((message) => message)
                    .join("\n")})`
                );
              }
            }
          } catch (e) {
            state.errorMessages = { message: e.message };
            message.error(`作成できませんでした。(${e.message})`);
          }
        } else {
          if (await update(resource)) {
            message.success(`更新しました。`);
            handleCancel();
            context.emit("updated", resource);

            state.currentResource = null;
          } else {
            state.errorMessages = unref(updateErrorMessages);
            if (unref(inlineEditMode) && state.errorMessages != null) {
              message.error(
                `更新できませんでした。(${Object.values(state.errorMessages)
                  .map((message) => message)
                  .join("\n")})`
              );
            }
          }
        }
      }
    };

    const handleRemove = async (resource: any) => {
      if (await remove(resource)) {
        message.success(`削除しました。`);
        await refetch();
        context.emit("removed", resource);
      } else {
        const errorMessages = unref(removeErrorMessages);
        Object.keys(errorMessages).forEach((key) => {
          message.error(errorMessages[key]);
        });
      }
      handleCancel();
    };

    const handleTableChange = async (page: number) => {
      if (state.paginationObject !== false) {
        state.paginationObject.current = page;
        await refetch(unref(paginationVariables));
      }
    };

    const handleSetVisibleAttr = (key: string, visible: boolean) => {
      state.visibleAttrs[key] = visible;
    };

    const handlePageSizeOptionChange = (pageSizeOption: number) => {
      if (state.paginationObject !== false) {
        state.paginationObject.pageSize = pageSizeOption;
        state.paginationObject.current = 1;
      }
    };

    const handleExport = async (fileFormat: string) => {
      if (await exportData(fileFormat)) {
        message.success("ダウンロードしました。");
      } else {
        message.error("エラーが発生し、ダウンロードできませんでした。");
        const errorMessages = unref(exportErrorMessages);
        Object.keys(errorMessages).forEach((key) => {
          message.error(errorMessages[key]);
        });
      }
    };

    const handleImport = (file: any): boolean => {
      // promiseが終わる前にreturn trueする必要がある。
      // これをしないと余計なuploadが動く
      importData(file).then(async (result: boolean) => {
        if (result) {
          await refetch();
          message.success("アップロードしました。");
        } else {
          message.error("エラーが発生し、アップロードできませんでした。");
          const errorMessages = unref(importErrorMessages);
          Object.keys(errorMessages).forEach((key) => {
            message.error(errorMessages[key]);
          });
        }
      });
      return false;
    };

    return {
      ...toRefs(state),
      fileFormats,
      // pageSizeOptions,
      resources,
      columns,
      loading,
      draggable,
      inlineEditMode,
      drawerEditMode,
      isOpenModal,
      editableResourceAttrs,
      fields,
      handleAdd,
      handleEdit,
      handleCancel,
      handleRefetch,
      handleDragged,
      handleSave,
      handleTableChange,
      handleRemove,
      handleSetVisibleAttr,
      handlePageSizeOptionChange,
      handleExport,
      handleImport,
    };
  },
});

export default GenericList;
