/* eslint-disable */
import * as schema from "@/admin/schema/schema";

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type ReactiveFunction<TParam> = () => TParam;
export const AdminUserFragmentFragmentDoc = gql`
    fragment AdminUserFragment on AdminUser {
  id
  name
}
    `;
export const CatalanaLayFragmentFragmentDoc = gql`
    fragment CatalanaLayFragment on CatalanaLay {
  id
  code
  name
  note
}
    `;
export const ClientUserFragmentFragmentDoc = gql`
    fragment ClientUserFragment on ClientUser {
  id
  name
}
    `;
export const DeliveryPriceFragmentFragmentDoc = gql`
    fragment DeliveryPriceFragment on DeliveryPrice {
  id
  code
  prefecture
  price
  note
}
    `;
export const DeliveryRecordFragmentFragmentDoc = gql`
    fragment DeliveryRecordFragment on DeliveryRecord {
  id
  shippedNumber
  shippedAt
  adminUser {
    id
    name
  }
}
    `;
export const DeliveryRecordOrderFragmentFragmentDoc = gql`
    fragment DeliveryRecordOrderFragment on DeliveryRecordOrder {
  id
  quantity
  orderDelivery {
    id
    name
    zipCode
    city
    block
    building
    tel
    fax
    prefecture
  }
}
    `;
export const HolidayFragmentFragmentDoc = gql`
    fragment HolidayFragment on Holiday {
  id
  code
  name
  note
  date
  holidayType
}
    `;
export const ImpositionPropertyFragmentFragmentDoc = gql`
    fragment ImpositionPropertyFragment on ImpositionProperty {
  id
  code
  name
  note
}
    `;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  title
  content
  publishedAt
}
    `;
export const OrderCommentFileFragmentFragmentDoc = gql`
    fragment OrderCommentFileFragment on OrderCommentFile {
  id
  fileName
  fileSize
  mimeType
  originalPath
  thumbnailPath
}
    `;
export const OrderCommentFragmentFragmentDoc = gql`
    fragment OrderCommentFragment on OrderComment {
  id
  comment
  createdAt
  username
  commentType
  files {
    id
    fileName
    fileSize
    mimeType
    originalPath
    thumbnailPath
  }
}
    `;
export const OrderContactFragmentFragmentDoc = gql`
    fragment OrderContactFragment on OrderContact {
  id
  name
  zipCode
  city
  block
  building
  tel
  fax
  prefecture
  address
}
    `;
export const OrderDeliveryFragmentFragmentDoc = gql`
    fragment OrderDeliveryFragment on OrderDelivery {
  id
  name
  zipCode
  city
  block
  building
  tel
  fax
  prefecture
  quantity
  address
  shipperName
  parent {
    id
  }
  allDeliveredAt
  deliveryRecord {
    id
  }
}
    `;
export const OrderProductFragmentFragmentDoc = gql`
    fragment OrderProductFragment on OrderProduct {
  id
  quantity
  price
  basicDueDate
  name
  note
  suppliers {
    id
    name
  }
  canDownload
  productType
}
    `;
export const InnerCartonParamFragmentFragmentDoc = gql`
    fragment InnerCartonParamFragment on InnerCartonParam {
  id
  orderProduct {
    ...OrderProductFragment
  }
  janCode
  productName
  quantityPerInnerCartonText
  taxIncludedPrice
}
    ${OrderProductFragmentFragmentDoc}`;
export const PriceLabelParamFragmentFragmentDoc = gql`
    fragment PriceLabelParamFragment on PriceLabelParam {
  id
  orderProduct {
    ...OrderProductFragment
  }
  janCode
  taxIncludedPrice
}
    ${OrderProductFragmentFragmentDoc}`;
export const OrderFragmentFragmentDoc = gql`
    fragment OrderFragment on Order {
  id
  orderNo
  orderStatus {
    id
    name
    viewOrder
  }
  clientUser {
    id
    name
  }
  deliveryAt
  orderedAt
  applicationStatus
  price
  shippingFee
  amount
  setAmount
  product {
    id
    name
  }
  orderProduct {
    innerCartonParam {
      ...InnerCartonParamFragment
    }
    priceLabelParam {
      ...PriceLabelParamFragment
    }
  }
  parent {
    id
    name
  }
}
    ${InnerCartonParamFragmentFragmentDoc}
${PriceLabelParamFragmentFragmentDoc}`;
export const OrderProductFileFragmentFragmentDoc = gql`
    fragment OrderProductFileFragment on OrderProductFile {
  id
  fileName
  fileSize
  mimeType
  originalPath
  thumbnailPath
  createdAt
  createdBy {
    id
    name
  }
  disabled
}
    `;
export const OrderStatusFragmentFragmentDoc = gql`
    fragment OrderStatusFragment on OrderStatus {
  id
  name
}
    `;
export const ProductFileFragmentFragmentDoc = gql`
    fragment ProductFileFragment on ProductFile {
  id
  fileName
  fileSize
  mimeType
  originalPath
  thumbnailPath
}
    `;
export const ProductFragmentFragmentDoc = gql`
    fragment ProductFragment on Product {
  id
  name
  note
  thumbnailPath
  originalPath
  mimeType
  productType
  basicDueDate
  arrivalDate
  category {
    id
    name
  }
  canDownload
  catalanaLay {
    id
    name
  }
  backCatalanaLay {
    id
    name
  }
  designPatternCode
  impositionProperty {
    id
    name
  }
  priceType
  unitPrice
  unitBasicDueDate
  quantityPerBox
  hasMinPrice
  minLot
  minPrice
}
    `;
export const ProductPriceFragmentFragmentDoc = gql`
    fragment ProductPriceFragment on ProductPrice {
  id
  code
  quantity
  price
  basicDueDate
}
    `;
export const CreateOneAdminUserDocument = gql`
    mutation CreateOneAdminUser($input: CreateOneAdminUserInput!) {
  createOneAdminUser(input: $input) {
    adminUser {
      ...AdminUserFragment
    }
  }
}
    ${AdminUserFragmentFragmentDoc}`;

/**
 * __useCreateOneAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateOneAdminUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneAdminUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneAdminUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneAdminUserMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneAdminUserMutation, schema.CreateOneAdminUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneAdminUserMutation, schema.CreateOneAdminUserMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneAdminUserMutation, schema.CreateOneAdminUserMutationVariables>(CreateOneAdminUserDocument, options);
}
export type CreateOneAdminUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneAdminUserMutation, schema.CreateOneAdminUserMutationVariables>;
export const RemoveOneAdminUserDocument = gql`
    mutation RemoveOneAdminUser($input: RemoveOneAdminUserInput!) {
  removeOneAdminUser(input: $input) {
    adminUser {
      ...AdminUserFragment
    }
  }
}
    ${AdminUserFragmentFragmentDoc}`;

/**
 * __useRemoveOneAdminUserMutation__
 *
 * To run a mutation, you first call `useRemoveOneAdminUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneAdminUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneAdminUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneAdminUserMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneAdminUserMutation, schema.RemoveOneAdminUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneAdminUserMutation, schema.RemoveOneAdminUserMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneAdminUserMutation, schema.RemoveOneAdminUserMutationVariables>(RemoveOneAdminUserDocument, options);
}
export type RemoveOneAdminUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneAdminUserMutation, schema.RemoveOneAdminUserMutationVariables>;
export const UpdateOneAdminUserDocument = gql`
    mutation UpdateOneAdminUser($input: UpdateOneAdminUserInput!) {
  updateOneAdminUser(input: $input) {
    adminUser {
      ...AdminUserFragment
    }
  }
}
    ${AdminUserFragmentFragmentDoc}`;

/**
 * __useUpdateOneAdminUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneAdminUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAdminUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneAdminUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneAdminUserMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneAdminUserMutation, schema.UpdateOneAdminUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneAdminUserMutation, schema.UpdateOneAdminUserMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneAdminUserMutation, schema.UpdateOneAdminUserMutationVariables>(UpdateOneAdminUserDocument, options);
}
export type UpdateOneAdminUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneAdminUserMutation, schema.UpdateOneAdminUserMutationVariables>;
export const CreateAutoImpositionDocument = gql`
    mutation CreateAutoImposition($input: CreateAutoImpositionInput!) {
  createAutoImposition(input: $input) {
    orderProduct {
      ...OrderProductFragment
    }
  }
}
    ${OrderProductFragmentFragmentDoc}`;

/**
 * __useCreateAutoImpositionMutation__
 *
 * To run a mutation, you first call `useCreateAutoImpositionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutoImpositionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAutoImpositionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutoImpositionMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateAutoImpositionMutation, schema.CreateAutoImpositionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateAutoImpositionMutation, schema.CreateAutoImpositionMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateAutoImpositionMutation, schema.CreateAutoImpositionMutationVariables>(CreateAutoImpositionDocument, options);
}
export type CreateAutoImpositionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateAutoImpositionMutation, schema.CreateAutoImpositionMutationVariables>;
export const CreateOneCatalanaLayDocument = gql`
    mutation CreateOneCatalanaLay($input: CreateOneCatalanaLayInput!) {
  createOneCatalanaLay(input: $input) {
    catalanaLay {
      ...CatalanaLayFragment
    }
  }
}
    ${CatalanaLayFragmentFragmentDoc}`;

/**
 * __useCreateOneCatalanaLayMutation__
 *
 * To run a mutation, you first call `useCreateOneCatalanaLayMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCatalanaLayMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneCatalanaLayMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneCatalanaLayMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneCatalanaLayMutation, schema.CreateOneCatalanaLayMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneCatalanaLayMutation, schema.CreateOneCatalanaLayMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneCatalanaLayMutation, schema.CreateOneCatalanaLayMutationVariables>(CreateOneCatalanaLayDocument, options);
}
export type CreateOneCatalanaLayMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneCatalanaLayMutation, schema.CreateOneCatalanaLayMutationVariables>;
export const RemoveOneCatalanaLayDocument = gql`
    mutation RemoveOneCatalanaLay($input: RemoveOneCatalanaLayInput!) {
  removeOneCatalanaLay(input: $input) {
    catalanaLay {
      ...CatalanaLayFragment
    }
  }
}
    ${CatalanaLayFragmentFragmentDoc}`;

/**
 * __useRemoveOneCatalanaLayMutation__
 *
 * To run a mutation, you first call `useRemoveOneCatalanaLayMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneCatalanaLayMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneCatalanaLayMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneCatalanaLayMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneCatalanaLayMutation, schema.RemoveOneCatalanaLayMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneCatalanaLayMutation, schema.RemoveOneCatalanaLayMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneCatalanaLayMutation, schema.RemoveOneCatalanaLayMutationVariables>(RemoveOneCatalanaLayDocument, options);
}
export type RemoveOneCatalanaLayMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneCatalanaLayMutation, schema.RemoveOneCatalanaLayMutationVariables>;
export const UpdateOneCatalanaLayDocument = gql`
    mutation UpdateOneCatalanaLay($input: UpdateOneCatalanaLayInput!) {
  updateOneCatalanaLay(input: $input) {
    catalanaLay {
      ...CatalanaLayFragment
    }
  }
}
    ${CatalanaLayFragmentFragmentDoc}`;

/**
 * __useUpdateOneCatalanaLayMutation__
 *
 * To run a mutation, you first call `useUpdateOneCatalanaLayMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCatalanaLayMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneCatalanaLayMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneCatalanaLayMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneCatalanaLayMutation, schema.UpdateOneCatalanaLayMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneCatalanaLayMutation, schema.UpdateOneCatalanaLayMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneCatalanaLayMutation, schema.UpdateOneCatalanaLayMutationVariables>(UpdateOneCatalanaLayDocument, options);
}
export type UpdateOneCatalanaLayMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneCatalanaLayMutation, schema.UpdateOneCatalanaLayMutationVariables>;
export const CreateOneDeliveryPriceDocument = gql`
    mutation CreateOneDeliveryPrice($input: CreateOneDeliveryPriceInput!) {
  createOneDeliveryPrice(input: $input) {
    deliveryPrice {
      ...DeliveryPriceFragment
    }
  }
}
    ${DeliveryPriceFragmentFragmentDoc}`;

/**
 * __useCreateOneDeliveryPriceMutation__
 *
 * To run a mutation, you first call `useCreateOneDeliveryPriceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneDeliveryPriceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneDeliveryPriceMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneDeliveryPriceMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneDeliveryPriceMutation, schema.CreateOneDeliveryPriceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneDeliveryPriceMutation, schema.CreateOneDeliveryPriceMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneDeliveryPriceMutation, schema.CreateOneDeliveryPriceMutationVariables>(CreateOneDeliveryPriceDocument, options);
}
export type CreateOneDeliveryPriceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneDeliveryPriceMutation, schema.CreateOneDeliveryPriceMutationVariables>;
export const RemoveOneDeliveryPriceDocument = gql`
    mutation RemoveOneDeliveryPrice($input: RemoveOneDeliveryPriceInput!) {
  removeOneDeliveryPrice(input: $input) {
    deliveryPrice {
      ...DeliveryPriceFragment
    }
  }
}
    ${DeliveryPriceFragmentFragmentDoc}`;

/**
 * __useRemoveOneDeliveryPriceMutation__
 *
 * To run a mutation, you first call `useRemoveOneDeliveryPriceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneDeliveryPriceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneDeliveryPriceMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneDeliveryPriceMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneDeliveryPriceMutation, schema.RemoveOneDeliveryPriceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneDeliveryPriceMutation, schema.RemoveOneDeliveryPriceMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneDeliveryPriceMutation, schema.RemoveOneDeliveryPriceMutationVariables>(RemoveOneDeliveryPriceDocument, options);
}
export type RemoveOneDeliveryPriceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneDeliveryPriceMutation, schema.RemoveOneDeliveryPriceMutationVariables>;
export const UpdateOneDeliveryPriceDocument = gql`
    mutation UpdateOneDeliveryPrice($input: UpdateOneDeliveryPriceInput!) {
  updateOneDeliveryPrice(input: $input) {
    deliveryPrice {
      ...DeliveryPriceFragment
    }
  }
}
    ${DeliveryPriceFragmentFragmentDoc}`;

/**
 * __useUpdateOneDeliveryPriceMutation__
 *
 * To run a mutation, you first call `useUpdateOneDeliveryPriceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneDeliveryPriceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneDeliveryPriceMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneDeliveryPriceMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneDeliveryPriceMutation, schema.UpdateOneDeliveryPriceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneDeliveryPriceMutation, schema.UpdateOneDeliveryPriceMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneDeliveryPriceMutation, schema.UpdateOneDeliveryPriceMutationVariables>(UpdateOneDeliveryPriceDocument, options);
}
export type UpdateOneDeliveryPriceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneDeliveryPriceMutation, schema.UpdateOneDeliveryPriceMutationVariables>;
export const CreateOneDeliveryRecordDocument = gql`
    mutation CreateOneDeliveryRecord($input: CreateOneDeliveryRecordInput!) {
  createOneDeliveryRecord(input: $input) {
    deliveryRecord {
      ...DeliveryRecordFragment
    }
  }
}
    ${DeliveryRecordFragmentFragmentDoc}`;

/**
 * __useCreateOneDeliveryRecordMutation__
 *
 * To run a mutation, you first call `useCreateOneDeliveryRecordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneDeliveryRecordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneDeliveryRecordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneDeliveryRecordMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneDeliveryRecordMutation, schema.CreateOneDeliveryRecordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneDeliveryRecordMutation, schema.CreateOneDeliveryRecordMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneDeliveryRecordMutation, schema.CreateOneDeliveryRecordMutationVariables>(CreateOneDeliveryRecordDocument, options);
}
export type CreateOneDeliveryRecordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneDeliveryRecordMutation, schema.CreateOneDeliveryRecordMutationVariables>;
export const RemoveOneDeliveryRecordDocument = gql`
    mutation RemoveOneDeliveryRecord($input: RemoveOneDeliveryRecordInput!) {
  removeOneDeliveryRecord(input: $input) {
    deliveryRecord {
      ...DeliveryRecordFragment
    }
  }
}
    ${DeliveryRecordFragmentFragmentDoc}`;

/**
 * __useRemoveOneDeliveryRecordMutation__
 *
 * To run a mutation, you first call `useRemoveOneDeliveryRecordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneDeliveryRecordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneDeliveryRecordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneDeliveryRecordMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneDeliveryRecordMutation, schema.RemoveOneDeliveryRecordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneDeliveryRecordMutation, schema.RemoveOneDeliveryRecordMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneDeliveryRecordMutation, schema.RemoveOneDeliveryRecordMutationVariables>(RemoveOneDeliveryRecordDocument, options);
}
export type RemoveOneDeliveryRecordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneDeliveryRecordMutation, schema.RemoveOneDeliveryRecordMutationVariables>;
export const UpdateOneDeliveryRecordDocument = gql`
    mutation UpdateOneDeliveryRecord($input: UpdateOneDeliveryRecordInput!) {
  updateOneDeliveryRecord(input: $input) {
    deliveryRecord {
      ...DeliveryRecordFragment
    }
  }
}
    ${DeliveryRecordFragmentFragmentDoc}`;

/**
 * __useUpdateOneDeliveryRecordMutation__
 *
 * To run a mutation, you first call `useUpdateOneDeliveryRecordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneDeliveryRecordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneDeliveryRecordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneDeliveryRecordMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneDeliveryRecordMutation, schema.UpdateOneDeliveryRecordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneDeliveryRecordMutation, schema.UpdateOneDeliveryRecordMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneDeliveryRecordMutation, schema.UpdateOneDeliveryRecordMutationVariables>(UpdateOneDeliveryRecordDocument, options);
}
export type UpdateOneDeliveryRecordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneDeliveryRecordMutation, schema.UpdateOneDeliveryRecordMutationVariables>;
export const CreateOneImpositionPropertyDocument = gql`
    mutation CreateOneImpositionProperty($input: CreateOneImpositionPropertyInput!) {
  createOneImpositionProperty(input: $input) {
    impositionProperty {
      ...ImpositionPropertyFragment
    }
  }
}
    ${ImpositionPropertyFragmentFragmentDoc}`;

/**
 * __useCreateOneImpositionPropertyMutation__
 *
 * To run a mutation, you first call `useCreateOneImpositionPropertyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneImpositionPropertyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneImpositionPropertyMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneImpositionPropertyMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneImpositionPropertyMutation, schema.CreateOneImpositionPropertyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneImpositionPropertyMutation, schema.CreateOneImpositionPropertyMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneImpositionPropertyMutation, schema.CreateOneImpositionPropertyMutationVariables>(CreateOneImpositionPropertyDocument, options);
}
export type CreateOneImpositionPropertyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneImpositionPropertyMutation, schema.CreateOneImpositionPropertyMutationVariables>;
export const RemoveOneImpositionPropertyDocument = gql`
    mutation RemoveOneImpositionProperty($input: RemoveOneImpositionPropertyInput!) {
  removeOneImpositionProperty(input: $input) {
    impositionProperty {
      ...ImpositionPropertyFragment
    }
  }
}
    ${ImpositionPropertyFragmentFragmentDoc}`;

/**
 * __useRemoveOneImpositionPropertyMutation__
 *
 * To run a mutation, you first call `useRemoveOneImpositionPropertyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneImpositionPropertyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneImpositionPropertyMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneImpositionPropertyMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneImpositionPropertyMutation, schema.RemoveOneImpositionPropertyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneImpositionPropertyMutation, schema.RemoveOneImpositionPropertyMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneImpositionPropertyMutation, schema.RemoveOneImpositionPropertyMutationVariables>(RemoveOneImpositionPropertyDocument, options);
}
export type RemoveOneImpositionPropertyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneImpositionPropertyMutation, schema.RemoveOneImpositionPropertyMutationVariables>;
export const UpdateOneImpositionPropertyDocument = gql`
    mutation UpdateOneImpositionProperty($input: UpdateOneImpositionPropertyInput!) {
  updateOneImpositionProperty(input: $input) {
    impositionProperty {
      ...ImpositionPropertyFragment
    }
  }
}
    ${ImpositionPropertyFragmentFragmentDoc}`;

/**
 * __useUpdateOneImpositionPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateOneImpositionPropertyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneImpositionPropertyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneImpositionPropertyMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneImpositionPropertyMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneImpositionPropertyMutation, schema.UpdateOneImpositionPropertyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneImpositionPropertyMutation, schema.UpdateOneImpositionPropertyMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneImpositionPropertyMutation, schema.UpdateOneImpositionPropertyMutationVariables>(UpdateOneImpositionPropertyDocument, options);
}
export type UpdateOneImpositionPropertyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneImpositionPropertyMutation, schema.UpdateOneImpositionPropertyMutationVariables>;
export const CreateOneNotificationDocument = gql`
    mutation CreateOneNotification($input: CreateOneNotificationInput!) {
  createOneNotification(input: $input) {
    notification {
      ...NotificationFragment
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useCreateOneNotificationMutation__
 *
 * To run a mutation, you first call `useCreateOneNotificationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneNotificationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneNotificationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneNotificationMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneNotificationMutation, schema.CreateOneNotificationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneNotificationMutation, schema.CreateOneNotificationMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneNotificationMutation, schema.CreateOneNotificationMutationVariables>(CreateOneNotificationDocument, options);
}
export type CreateOneNotificationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneNotificationMutation, schema.CreateOneNotificationMutationVariables>;
export const RemoveOneNotificationDocument = gql`
    mutation RemoveOneNotification($input: RemoveOneNotificationInput!) {
  removeOneNotification(input: $input) {
    notification {
      ...NotificationFragment
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useRemoveOneNotificationMutation__
 *
 * To run a mutation, you first call `useRemoveOneNotificationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneNotificationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneNotificationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneNotificationMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneNotificationMutation, schema.RemoveOneNotificationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneNotificationMutation, schema.RemoveOneNotificationMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneNotificationMutation, schema.RemoveOneNotificationMutationVariables>(RemoveOneNotificationDocument, options);
}
export type RemoveOneNotificationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneNotificationMutation, schema.RemoveOneNotificationMutationVariables>;
export const UpdateOneNotificationDocument = gql`
    mutation UpdateOneNotification($input: UpdateOneNotificationInput!) {
  updateOneNotification(input: $input) {
    notification {
      ...NotificationFragment
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useUpdateOneNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateOneNotificationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneNotificationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneNotificationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneNotificationMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneNotificationMutation, schema.UpdateOneNotificationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneNotificationMutation, schema.UpdateOneNotificationMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneNotificationMutation, schema.UpdateOneNotificationMutationVariables>(UpdateOneNotificationDocument, options);
}
export type UpdateOneNotificationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneNotificationMutation, schema.UpdateOneNotificationMutationVariables>;
export const RejectOrderDocument = gql`
    mutation RejectOrder($input: RejectOrderInput!) {
  rejectOrder(input: $input) {
    order {
      ...OrderFragment
    }
    orderComment {
      ...OrderCommentFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}
${OrderCommentFragmentFragmentDoc}`;

/**
 * __useRejectOrderMutation__
 *
 * To run a mutation, you first call `useRejectOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRejectOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRejectOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRejectOrderMutation(options: VueApolloComposable.UseMutationOptions<schema.RejectOrderMutation, schema.RejectOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RejectOrderMutation, schema.RejectOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RejectOrderMutation, schema.RejectOrderMutationVariables>(RejectOrderDocument, options);
}
export type RejectOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RejectOrderMutation, schema.RejectOrderMutationVariables>;
export const UpdateOneOrderDocument = gql`
    mutation UpdateOneOrder($input: UpdateOneOrderInput!) {
  updateOneOrder(input: $input) {
    order {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useUpdateOneOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneOrderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOrderMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderMutation, schema.UpdateOneOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderMutation, schema.UpdateOneOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneOrderMutation, schema.UpdateOneOrderMutationVariables>(UpdateOneOrderDocument, options);
}
export type UpdateOneOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneOrderMutation, schema.UpdateOneOrderMutationVariables>;
export const CreateOneOrderCommentDocument = gql`
    mutation CreateOneOrderComment($input: CreateOneOrderCommentInput!) {
  createOneOrderComment(input: $input) {
    orderComment {
      ...OrderCommentFragment
    }
  }
}
    ${OrderCommentFragmentFragmentDoc}`;

/**
 * __useCreateOneOrderCommentMutation__
 *
 * To run a mutation, you first call `useCreateOneOrderCommentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrderCommentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOneOrderCommentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneOrderCommentMutation(options: VueApolloComposable.UseMutationOptions<schema.CreateOneOrderCommentMutation, schema.CreateOneOrderCommentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CreateOneOrderCommentMutation, schema.CreateOneOrderCommentMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CreateOneOrderCommentMutation, schema.CreateOneOrderCommentMutationVariables>(CreateOneOrderCommentDocument, options);
}
export type CreateOneOrderCommentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CreateOneOrderCommentMutation, schema.CreateOneOrderCommentMutationVariables>;
export const RemoveOneOrderCommentFileDocument = gql`
    mutation RemoveOneOrderCommentFile($input: RemoveOneOrderCommentFileInput!) {
  removeOneOrderCommentFile(input: $input) {
    orderCommentFile {
      ...OrderCommentFileFragment
    }
  }
}
    ${OrderCommentFileFragmentFragmentDoc}`;

/**
 * __useRemoveOneOrderCommentFileMutation__
 *
 * To run a mutation, you first call `useRemoveOneOrderCommentFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneOrderCommentFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneOrderCommentFileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneOrderCommentFileMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderCommentFileMutation, schema.RemoveOneOrderCommentFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneOrderCommentFileMutation, schema.RemoveOneOrderCommentFileMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneOrderCommentFileMutation, schema.RemoveOneOrderCommentFileMutationVariables>(RemoveOneOrderCommentFileDocument, options);
}
export type RemoveOneOrderCommentFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneOrderCommentFileMutation, schema.RemoveOneOrderCommentFileMutationVariables>;
export const UpdateOneOrderProductFileDocument = gql`
    mutation UpdateOneOrderProductFile($input: UpdateOneOrderProductFileInput!) {
  updateOneOrderProductFile(input: $input) {
    orderProductFile {
      ...OrderProductFileFragment
    }
  }
}
    ${OrderProductFileFragmentFragmentDoc}`;

/**
 * __useUpdateOneOrderProductFileMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderProductFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderProductFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneOrderProductFileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOrderProductFileMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderProductFileMutation, schema.UpdateOneOrderProductFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneOrderProductFileMutation, schema.UpdateOneOrderProductFileMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneOrderProductFileMutation, schema.UpdateOneOrderProductFileMutationVariables>(UpdateOneOrderProductFileDocument, options);
}
export type UpdateOneOrderProductFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneOrderProductFileMutation, schema.UpdateOneOrderProductFileMutationVariables>;
export const CopyOneProductDocument = gql`
    mutation CopyOneProduct($input: CopyOneProductInput!) {
  copyOneProduct(input: $input) {
    product {
      ...ProductFragment
    }
  }
}
    ${ProductFragmentFragmentDoc}`;

/**
 * __useCopyOneProductMutation__
 *
 * To run a mutation, you first call `useCopyOneProductMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCopyOneProductMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCopyOneProductMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCopyOneProductMutation(options: VueApolloComposable.UseMutationOptions<schema.CopyOneProductMutation, schema.CopyOneProductMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.CopyOneProductMutation, schema.CopyOneProductMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.CopyOneProductMutation, schema.CopyOneProductMutationVariables>(CopyOneProductDocument, options);
}
export type CopyOneProductMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.CopyOneProductMutation, schema.CopyOneProductMutationVariables>;
export const RemoveOneProductDocument = gql`
    mutation RemoveOneProduct($input: RemoveOneProductInput!) {
  removeOneProduct(input: $input) {
    product {
      id
    }
  }
}
    `;

/**
 * __useRemoveOneProductMutation__
 *
 * To run a mutation, you first call `useRemoveOneProductMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneProductMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneProductMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneProductMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneProductMutation, schema.RemoveOneProductMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneProductMutation, schema.RemoveOneProductMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneProductMutation, schema.RemoveOneProductMutationVariables>(RemoveOneProductDocument, options);
}
export type RemoveOneProductMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneProductMutation, schema.RemoveOneProductMutationVariables>;
export const UpdateOneProductDocument = gql`
    mutation UpdateOneProduct($input: UpdateOneProductInput!) {
  updateOneProduct(input: $input) {
    product {
      ...ProductFragment
    }
  }
}
    ${ProductFragmentFragmentDoc}`;

/**
 * __useUpdateOneProductMutation__
 *
 * To run a mutation, you first call `useUpdateOneProductMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneProductMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOneProductMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneProductMutation(options: VueApolloComposable.UseMutationOptions<schema.UpdateOneProductMutation, schema.UpdateOneProductMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.UpdateOneProductMutation, schema.UpdateOneProductMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.UpdateOneProductMutation, schema.UpdateOneProductMutationVariables>(UpdateOneProductDocument, options);
}
export type UpdateOneProductMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.UpdateOneProductMutation, schema.UpdateOneProductMutationVariables>;
export const RemoveOneProductFileDocument = gql`
    mutation RemoveOneProductFile($input: RemoveOneProductFileInput!) {
  removeOneProductFile(input: $input) {
    productFile {
      ...ProductFileFragment
    }
  }
}
    ${ProductFileFragmentFragmentDoc}`;

/**
 * __useRemoveOneProductFileMutation__
 *
 * To run a mutation, you first call `useRemoveOneProductFileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneProductFileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveOneProductFileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOneProductFileMutation(options: VueApolloComposable.UseMutationOptions<schema.RemoveOneProductFileMutation, schema.RemoveOneProductFileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<schema.RemoveOneProductFileMutation, schema.RemoveOneProductFileMutationVariables>>) {
  return VueApolloComposable.useMutation<schema.RemoveOneProductFileMutation, schema.RemoveOneProductFileMutationVariables>(RemoveOneProductFileDocument, options);
}
export type RemoveOneProductFileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<schema.RemoveOneProductFileMutation, schema.RemoveOneProductFileMutationVariables>;
export const GetManyAdminUserDocument = gql`
    query GetManyAdminUser {
  adminUsers {
    ...AdminUserFragment
  }
}
    ${AdminUserFragmentFragmentDoc}`;

/**
 * __useGetManyAdminUserQuery__
 *
 * To run a query within a Vue component, call `useGetManyAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyAdminUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyAdminUserQuery();
 */
export function useGetManyAdminUserQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyAdminUserQuery, schema.GetManyAdminUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyAdminUserQuery, schema.GetManyAdminUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyAdminUserQuery, schema.GetManyAdminUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyAdminUserQuery, schema.GetManyAdminUserQueryVariables>(GetManyAdminUserDocument, {}, options);
}
export type GetManyAdminUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyAdminUserQuery, schema.GetManyAdminUserQueryVariables>;
export const GetOneAdminUserDocument = gql`
    query GetOneAdminUser($id: ID!) {
  adminUser(id: $id) {
    ...AdminUserFragment
  }
}
    ${AdminUserFragmentFragmentDoc}`;

/**
 * __useGetOneAdminUserQuery__
 *
 * To run a query within a Vue component, call `useGetOneAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneAdminUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneAdminUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneAdminUserQuery(variables: schema.GetOneAdminUserQueryVariables | VueCompositionApi.Ref<schema.GetOneAdminUserQueryVariables> | ReactiveFunction<schema.GetOneAdminUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneAdminUserQuery, schema.GetOneAdminUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneAdminUserQuery, schema.GetOneAdminUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneAdminUserQuery, schema.GetOneAdminUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneAdminUserQuery, schema.GetOneAdminUserQueryVariables>(GetOneAdminUserDocument, variables, options);
}
export type GetOneAdminUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneAdminUserQuery, schema.GetOneAdminUserQueryVariables>;
export const GetManyCatalanaLayDocument = gql`
    query GetManyCatalanaLay {
  catalanaLays {
    ...CatalanaLayFragment
  }
}
    ${CatalanaLayFragmentFragmentDoc}`;

/**
 * __useGetManyCatalanaLayQuery__
 *
 * To run a query within a Vue component, call `useGetManyCatalanaLayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCatalanaLayQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyCatalanaLayQuery();
 */
export function useGetManyCatalanaLayQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyCatalanaLayQuery, schema.GetManyCatalanaLayQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyCatalanaLayQuery, schema.GetManyCatalanaLayQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyCatalanaLayQuery, schema.GetManyCatalanaLayQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyCatalanaLayQuery, schema.GetManyCatalanaLayQueryVariables>(GetManyCatalanaLayDocument, {}, options);
}
export type GetManyCatalanaLayQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyCatalanaLayQuery, schema.GetManyCatalanaLayQueryVariables>;
export const GetOneCatalanaLayDocument = gql`
    query GetOneCatalanaLay($id: ID!) {
  catalanaLay(id: $id) {
    ...CatalanaLayFragment
  }
}
    ${CatalanaLayFragmentFragmentDoc}`;

/**
 * __useGetOneCatalanaLayQuery__
 *
 * To run a query within a Vue component, call `useGetOneCatalanaLayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneCatalanaLayQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneCatalanaLayQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneCatalanaLayQuery(variables: schema.GetOneCatalanaLayQueryVariables | VueCompositionApi.Ref<schema.GetOneCatalanaLayQueryVariables> | ReactiveFunction<schema.GetOneCatalanaLayQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneCatalanaLayQuery, schema.GetOneCatalanaLayQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneCatalanaLayQuery, schema.GetOneCatalanaLayQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneCatalanaLayQuery, schema.GetOneCatalanaLayQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneCatalanaLayQuery, schema.GetOneCatalanaLayQueryVariables>(GetOneCatalanaLayDocument, variables, options);
}
export type GetOneCatalanaLayQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneCatalanaLayQuery, schema.GetOneCatalanaLayQueryVariables>;
export const GetManyDeliveryPriceDocument = gql`
    query GetManyDeliveryPrice {
  deliveryPrices {
    ...DeliveryPriceFragment
  }
}
    ${DeliveryPriceFragmentFragmentDoc}`;

/**
 * __useGetManyDeliveryPriceQuery__
 *
 * To run a query within a Vue component, call `useGetManyDeliveryPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDeliveryPriceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyDeliveryPriceQuery();
 */
export function useGetManyDeliveryPriceQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyDeliveryPriceQuery, schema.GetManyDeliveryPriceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyDeliveryPriceQuery, schema.GetManyDeliveryPriceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyDeliveryPriceQuery, schema.GetManyDeliveryPriceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyDeliveryPriceQuery, schema.GetManyDeliveryPriceQueryVariables>(GetManyDeliveryPriceDocument, {}, options);
}
export type GetManyDeliveryPriceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyDeliveryPriceQuery, schema.GetManyDeliveryPriceQueryVariables>;
export const GetManyDeliveryRecordDocument = gql`
    query GetManyDeliveryRecord {
  deliveryRecords {
    ...DeliveryRecordFragment
    deliveryRecordOrders {
      quantity
      orderDelivery {
        ...OrderDeliveryFragment
        order {
          ...OrderFragment
          orderProduct {
            ...OrderProductFragment
          }
          clientUserGroup {
            id
            name
          }
          suppliers {
            id
            name
          }
        }
      }
    }
  }
}
    ${DeliveryRecordFragmentFragmentDoc}
${OrderDeliveryFragmentFragmentDoc}
${OrderFragmentFragmentDoc}
${OrderProductFragmentFragmentDoc}`;

/**
 * __useGetManyDeliveryRecordQuery__
 *
 * To run a query within a Vue component, call `useGetManyDeliveryRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyDeliveryRecordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyDeliveryRecordQuery();
 */
export function useGetManyDeliveryRecordQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyDeliveryRecordQuery, schema.GetManyDeliveryRecordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyDeliveryRecordQuery, schema.GetManyDeliveryRecordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyDeliveryRecordQuery, schema.GetManyDeliveryRecordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyDeliveryRecordQuery, schema.GetManyDeliveryRecordQueryVariables>(GetManyDeliveryRecordDocument, {}, options);
}
export type GetManyDeliveryRecordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyDeliveryRecordQuery, schema.GetManyDeliveryRecordQueryVariables>;
export const GetOneDeliveryRecordDocument = gql`
    query GetOneDeliveryRecord($id: ID!) {
  deliveryRecord(id: $id) {
    ...DeliveryRecordFragment
    deliveryRecordOrders {
      quantity
      orderDelivery {
        ...OrderDeliveryFragment
        order {
          ...OrderFragment
          orderProduct {
            ...OrderProductFragment
          }
          clientUserGroup {
            id
            name
          }
          suppliers {
            id
            name
          }
        }
      }
    }
  }
}
    ${DeliveryRecordFragmentFragmentDoc}
${OrderDeliveryFragmentFragmentDoc}
${OrderFragmentFragmentDoc}
${OrderProductFragmentFragmentDoc}`;

/**
 * __useGetOneDeliveryRecordQuery__
 *
 * To run a query within a Vue component, call `useGetOneDeliveryRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneDeliveryRecordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneDeliveryRecordQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneDeliveryRecordQuery(variables: schema.GetOneDeliveryRecordQueryVariables | VueCompositionApi.Ref<schema.GetOneDeliveryRecordQueryVariables> | ReactiveFunction<schema.GetOneDeliveryRecordQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneDeliveryRecordQuery, schema.GetOneDeliveryRecordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneDeliveryRecordQuery, schema.GetOneDeliveryRecordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneDeliveryRecordQuery, schema.GetOneDeliveryRecordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneDeliveryRecordQuery, schema.GetOneDeliveryRecordQueryVariables>(GetOneDeliveryRecordDocument, variables, options);
}
export type GetOneDeliveryRecordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneDeliveryRecordQuery, schema.GetOneDeliveryRecordQueryVariables>;
export const GetManyHolidayDocument = gql`
    query GetManyHoliday {
  holidays {
    ...HolidayFragment
  }
}
    ${HolidayFragmentFragmentDoc}`;

/**
 * __useGetManyHolidayQuery__
 *
 * To run a query within a Vue component, call `useGetManyHolidayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyHolidayQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyHolidayQuery();
 */
export function useGetManyHolidayQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables>(GetManyHolidayDocument, {}, options);
}
export type GetManyHolidayQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyHolidayQuery, schema.GetManyHolidayQueryVariables>;
export const GetManyImpositionPropertyDocument = gql`
    query GetManyImpositionProperty {
  impositionProperties {
    ...ImpositionPropertyFragment
  }
}
    ${ImpositionPropertyFragmentFragmentDoc}`;

/**
 * __useGetManyImpositionPropertyQuery__
 *
 * To run a query within a Vue component, call `useGetManyImpositionPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyImpositionPropertyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyImpositionPropertyQuery();
 */
export function useGetManyImpositionPropertyQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyImpositionPropertyQuery, schema.GetManyImpositionPropertyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyImpositionPropertyQuery, schema.GetManyImpositionPropertyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyImpositionPropertyQuery, schema.GetManyImpositionPropertyQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyImpositionPropertyQuery, schema.GetManyImpositionPropertyQueryVariables>(GetManyImpositionPropertyDocument, {}, options);
}
export type GetManyImpositionPropertyQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyImpositionPropertyQuery, schema.GetManyImpositionPropertyQueryVariables>;
export const GetOneImpositionPropertyDocument = gql`
    query GetOneImpositionProperty($id: ID!) {
  impositionProperty(id: $id) {
    ...ImpositionPropertyFragment
  }
}
    ${ImpositionPropertyFragmentFragmentDoc}`;

/**
 * __useGetOneImpositionPropertyQuery__
 *
 * To run a query within a Vue component, call `useGetOneImpositionPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneImpositionPropertyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneImpositionPropertyQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneImpositionPropertyQuery(variables: schema.GetOneImpositionPropertyQueryVariables | VueCompositionApi.Ref<schema.GetOneImpositionPropertyQueryVariables> | ReactiveFunction<schema.GetOneImpositionPropertyQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneImpositionPropertyQuery, schema.GetOneImpositionPropertyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneImpositionPropertyQuery, schema.GetOneImpositionPropertyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneImpositionPropertyQuery, schema.GetOneImpositionPropertyQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneImpositionPropertyQuery, schema.GetOneImpositionPropertyQueryVariables>(GetOneImpositionPropertyDocument, variables, options);
}
export type GetOneImpositionPropertyQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneImpositionPropertyQuery, schema.GetOneImpositionPropertyQueryVariables>;
export const GetManyNotificationDocument = gql`
    query GetManyNotification {
  notifications {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useGetManyNotificationQuery__
 *
 * To run a query within a Vue component, call `useGetManyNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyNotificationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyNotificationQuery();
 */
export function useGetManyNotificationQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables>(GetManyNotificationDocument, {}, options);
}
export type GetManyNotificationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyNotificationQuery, schema.GetManyNotificationQueryVariables>;
export const GetOneNotificationDocument = gql`
    query GetOneNotification($id: ID!) {
  notification(id: $id) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useGetOneNotificationQuery__
 *
 * To run a query within a Vue component, call `useGetOneNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneNotificationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneNotificationQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneNotificationQuery(variables: schema.GetOneNotificationQueryVariables | VueCompositionApi.Ref<schema.GetOneNotificationQueryVariables> | ReactiveFunction<schema.GetOneNotificationQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables>(GetOneNotificationDocument, variables, options);
}
export type GetOneNotificationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneNotificationQuery, schema.GetOneNotificationQueryVariables>;
export const GetManyOrderDocument = gql`
    query GetManyOrder {
  orders {
    ...OrderFragment
    clientUserGroup {
      id
      name
    }
    suppliers {
      id
      name
    }
    orderProduct {
      ...OrderProductFragment
    }
    product {
      ...ProductFragment
      prices {
        ...ProductPriceFragment
      }
    }
    orderDeliveries {
      ...OrderDeliveryFragment
    }
    orderContacts {
      ...OrderContactFragment
    }
    children {
      ...OrderFragment
    }
  }
}
    ${OrderFragmentFragmentDoc}
${OrderProductFragmentFragmentDoc}
${ProductFragmentFragmentDoc}
${ProductPriceFragmentFragmentDoc}
${OrderDeliveryFragmentFragmentDoc}
${OrderContactFragmentFragmentDoc}`;

/**
 * __useGetManyOrderQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderQuery();
 */
export function useGetManyOrderQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables>(GetManyOrderDocument, {}, options);
}
export type GetManyOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderQuery, schema.GetManyOrderQueryVariables>;
export const GetOneOrderDocument = gql`
    query GetOneOrder($id: ID!) {
  order(id: $id) {
    ...OrderFragment
    clientUserGroup {
      id
      name
    }
    orderProduct {
      ...OrderProductFragment
      files {
        ...OrderProductFileFragment
      }
      imposition {
        ...OrderProductFileFragment
      }
    }
    product {
      ...ProductFragment
      prices {
        ...ProductPriceFragment
      }
    }
    orderDeliveries {
      ...OrderDeliveryFragment
    }
    orderContacts {
      ...OrderContactFragment
    }
    children {
      ...OrderFragment
      orderProduct {
        ...OrderProductFragment
      }
    }
  }
}
    ${OrderFragmentFragmentDoc}
${OrderProductFragmentFragmentDoc}
${OrderProductFileFragmentFragmentDoc}
${ProductFragmentFragmentDoc}
${ProductPriceFragmentFragmentDoc}
${OrderDeliveryFragmentFragmentDoc}
${OrderContactFragmentFragmentDoc}`;

/**
 * __useGetOneOrderQuery__
 *
 * To run a query within a Vue component, call `useGetOneOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneOrderQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneOrderQuery(variables: schema.GetOneOrderQueryVariables | VueCompositionApi.Ref<schema.GetOneOrderQueryVariables> | ReactiveFunction<schema.GetOneOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables>(GetOneOrderDocument, variables, options);
}
export type GetOneOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneOrderQuery, schema.GetOneOrderQueryVariables>;
export const GetManyOrderCommentDocument = gql`
    query GetManyOrderComment($orderId: ID!) {
  orderComments(orderId: $orderId) {
    ...OrderCommentFragment
  }
}
    ${OrderCommentFragmentFragmentDoc}`;

/**
 * __useGetManyOrderCommentQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderCommentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderCommentQuery({
 *   orderId: // value for 'orderId'
 * });
 */
export function useGetManyOrderCommentQuery(variables: schema.GetManyOrderCommentQueryVariables | VueCompositionApi.Ref<schema.GetManyOrderCommentQueryVariables> | ReactiveFunction<schema.GetManyOrderCommentQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables>(GetManyOrderCommentDocument, variables, options);
}
export type GetManyOrderCommentQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderCommentQuery, schema.GetManyOrderCommentQueryVariables>;
export const GetManyOrderDeliveryDocument = gql`
    query GetManyOrderDelivery($notAllDelivered: Boolean, $parentOnly: Boolean) {
  orderDeliveries(notAllDelivered: $notAllDelivered, parentOnly: $parentOnly) {
    ...OrderDeliveryFragment
    order {
      ...OrderFragment
      orderProduct {
        ...OrderProductFragment
      }
      clientUserGroup {
        name
      }
    }
    children {
      ...OrderDeliveryFragment
      order {
        ...OrderFragment
        orderProduct {
          ...OrderProductFragment
        }
        clientUserGroup {
          name
        }
      }
    }
  }
}
    ${OrderDeliveryFragmentFragmentDoc}
${OrderFragmentFragmentDoc}
${OrderProductFragmentFragmentDoc}`;

/**
 * __useGetManyOrderDeliveryQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderDeliveryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderDeliveryQuery({
 *   notAllDelivered: // value for 'notAllDelivered'
 *   parentOnly: // value for 'parentOnly'
 * });
 */
export function useGetManyOrderDeliveryQuery(variables: schema.GetManyOrderDeliveryQueryVariables | VueCompositionApi.Ref<schema.GetManyOrderDeliveryQueryVariables> | ReactiveFunction<schema.GetManyOrderDeliveryQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderDeliveryQuery, schema.GetManyOrderDeliveryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderDeliveryQuery, schema.GetManyOrderDeliveryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderDeliveryQuery, schema.GetManyOrderDeliveryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderDeliveryQuery, schema.GetManyOrderDeliveryQueryVariables>(GetManyOrderDeliveryDocument, variables, options);
}
export type GetManyOrderDeliveryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderDeliveryQuery, schema.GetManyOrderDeliveryQueryVariables>;
export const GetManyOrderStatusDocument = gql`
    query GetManyOrderStatus {
  orderStatuses {
    ...OrderStatusFragment
  }
}
    ${OrderStatusFragmentFragmentDoc}`;

/**
 * __useGetManyOrderStatusQuery__
 *
 * To run a query within a Vue component, call `useGetManyOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrderStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyOrderStatusQuery();
 */
export function useGetManyOrderStatusQuery(options: VueApolloComposable.UseQueryOptions<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables>(GetManyOrderStatusDocument, {}, options);
}
export type GetManyOrderStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyOrderStatusQuery, schema.GetManyOrderStatusQueryVariables>;
export const GetOneProductDocument = gql`
    query GetOneProduct($id: ID!) {
  product(id: $id) {
    ...ProductFragment
    suppliers {
      id
      name
    }
  }
}
    ${ProductFragmentFragmentDoc}`;

/**
 * __useGetOneProductQuery__
 *
 * To run a query within a Vue component, call `useGetOneProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneProductQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneProductQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneProductQuery(variables: schema.GetOneProductQueryVariables | VueCompositionApi.Ref<schema.GetOneProductQueryVariables> | ReactiveFunction<schema.GetOneProductQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneProductQuery, schema.GetOneProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneProductQuery, schema.GetOneProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneProductQuery, schema.GetOneProductQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneProductQuery, schema.GetOneProductQueryVariables>(GetOneProductDocument, variables, options);
}
export type GetOneProductQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneProductQuery, schema.GetOneProductQueryVariables>;
export const GetManyProductFileDocument = gql`
    query GetManyProductFile($productId: ID!) {
  productFiles(productId: $productId) {
    ...ProductFileFragment
  }
}
    ${ProductFileFragmentFragmentDoc}`;

/**
 * __useGetManyProductFileQuery__
 *
 * To run a query within a Vue component, call `useGetManyProductFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyProductFileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyProductFileQuery({
 *   productId: // value for 'productId'
 * });
 */
export function useGetManyProductFileQuery(variables: schema.GetManyProductFileQueryVariables | VueCompositionApi.Ref<schema.GetManyProductFileQueryVariables> | ReactiveFunction<schema.GetManyProductFileQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetManyProductFileQuery, schema.GetManyProductFileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyProductFileQuery, schema.GetManyProductFileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyProductFileQuery, schema.GetManyProductFileQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyProductFileQuery, schema.GetManyProductFileQueryVariables>(GetManyProductFileDocument, variables, options);
}
export type GetManyProductFileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyProductFileQuery, schema.GetManyProductFileQueryVariables>;
export const GetManyProductPriceDocument = gql`
    query GetManyProductPrice($productId: ID!) {
  productPrices(productId: $productId) {
    ...ProductPriceFragment
  }
}
    ${ProductPriceFragmentFragmentDoc}`;

/**
 * __useGetManyProductPriceQuery__
 *
 * To run a query within a Vue component, call `useGetManyProductPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyProductPriceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetManyProductPriceQuery({
 *   productId: // value for 'productId'
 * });
 */
export function useGetManyProductPriceQuery(variables: schema.GetManyProductPriceQueryVariables | VueCompositionApi.Ref<schema.GetManyProductPriceQueryVariables> | ReactiveFunction<schema.GetManyProductPriceQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetManyProductPriceQuery, schema.GetManyProductPriceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetManyProductPriceQuery, schema.GetManyProductPriceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetManyProductPriceQuery, schema.GetManyProductPriceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetManyProductPriceQuery, schema.GetManyProductPriceQueryVariables>(GetManyProductPriceDocument, variables, options);
}
export type GetManyProductPriceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetManyProductPriceQuery, schema.GetManyProductPriceQueryVariables>;
export const GetOneProductPriceDocument = gql`
    query GetOneProductPrice($id: ID!) {
  productPrice(id: $id) {
    ...ProductPriceFragment
  }
}
    ${ProductPriceFragmentFragmentDoc}`;

/**
 * __useGetOneProductPriceQuery__
 *
 * To run a query within a Vue component, call `useGetOneProductPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneProductPriceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOneProductPriceQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOneProductPriceQuery(variables: schema.GetOneProductPriceQueryVariables | VueCompositionApi.Ref<schema.GetOneProductPriceQueryVariables> | ReactiveFunction<schema.GetOneProductPriceQueryVariables>, options: VueApolloComposable.UseQueryOptions<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables>(GetOneProductPriceDocument, variables, options);
}
export type GetOneProductPriceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<schema.GetOneProductPriceQuery, schema.GetOneProductPriceQueryVariables>;