<style lang="less" scoped>
.layout {
  min-height: 100vh;

  /deep/ .ant-layout-sider-trigger {
    display: none;
  }
}
</style>
<template>
  <a-layout class="layout">
    <a-layout-sider
      v-if="hasSidemenu"
      :style="sidebarStyle"
      :collapsed="collapsed"
      :collapsible="collapsible"
    >
      <slot name="sidemenu"></slot>
    </a-layout-sider>
    <a-layout>
      <a-layout-header v-if="hasHeader" :style="headerStyle">
        <slot name="header"></slot>
      </a-layout-header>
      <a-layout-content :style="contentStyle">
        <slot></slot>
      </a-layout-content>
      <a-layout-footer v-if="hasFooter" :style="footerStyle">
        <slot name="footer"></slot>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent, SetupContext, reactive, toRefs, computed } from "vue";

type Props = {
  collapsible: boolean;
  defaultCollapsed: boolean;
  collapsedWidth: number;
  collapsed: boolean;
  sidebarStyle: Record<string, any>;
  headerStyle: Record<string, any>;
  contentStyle: Record<string, any>;
  footerStyle: Record<string, any>;
};

export default defineComponent({
  components: {},
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: true,
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
    defaultCollapsed: {
      type: Boolean,
      default: true,
    },
    collapsedWidth: {
      type: Number,
      default: 80,
    },
    sidebarStyle: {
      type: Object,
      default: () => ({}),
    },
    headerStyle: {
      type: Object,
      default: () => ({ background: "#fff", padding: 0 }),
    },
    contentStyle: {
      type: Object,
      default: () => ({ margin: "0" }),
    },
    footerStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: Props, context: SetupContext) {
    const state = reactive({
      selectedKeys: ["1"],
    });

    const hasSidemenu = computed(() => context.slots.sidemenu != null);
    const hasHeader = computed(() => context.slots.header != null);
    const hasFooter = computed(() => context.slots.footer != null);

    return {
      ...toRefs(state),
      hasSidemenu,
      hasHeader,
      hasFooter,
    };
  },
});
</script>
