/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  adminUserRole: AdminUserRole;
  canDeliveryRecord: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  disable: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export enum AdminUserRole {
  /** 外注スタッフ */
  Supplier = 'supplier',
  /** 全権管理者 */
  Admin = 'admin'
}

export enum ApplicationStatusType {
  Wait = 'wait',
  Draft = 'draft',
  Canceled = 'canceled',
  Approved = 'approved',
  Rejected = 'rejected'
}

/** Autogenerated input type of ApproveManyOrderApprovalRecord */
export type ApproveManyOrderApprovalRecordInput = {
  ids: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ApproveManyOrderApprovalRecord */
export type ApproveManyOrderApprovalRecordPayload = {
  __typename?: 'ApproveManyOrderApprovalRecordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderApprovalRecords?: Maybe<Array<OrderApprovalRecord>>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type AuthToken = {
  __typename?: 'AuthToken';
  accessToken: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  refreshToken: Scalars['String'];
  refreshTokenExpiredAt: Scalars['ISO8601DateTime'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CancelOrder */
export type CancelOrderInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CancelOrder */
export type CancelOrderPayload = {
  __typename?: 'CancelOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CatalanaLay = {
  __typename?: 'CatalanaLay';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  product: Product;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ClientApplicationFlow = {
  __typename?: 'ClientApplicationFlow';
  clientUserGroup: ClientUserGroup;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  routes: Array<ClientApplicationFlowRoute>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ClientApplicationFlowLevel = {
  __typename?: 'ClientApplicationFlowLevel';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  groupApprovers: Array<ClientUserGroup>;
  id: Scalars['ID'];
  level: Scalars['Int'];
  route: ClientApplicationFlowRoute;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  userApprovers: Array<ClientUser>;
};

export type ClientApplicationFlowRoute = {
  __typename?: 'ClientApplicationFlowRoute';
  condLowerPrice: Scalars['Int'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  flow: ClientApplicationFlow;
  id: Scalars['ID'];
  levels: Array<ClientApplicationFlowLevel>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ClientContact = {
  __typename?: 'ClientContact';
  address: Scalars['String'];
  block: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  clientUserGroup: ClientUserGroup;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  default: Scalars['Boolean'];
  fax: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  prefecture: Prefecture;
  tel: Scalars['String'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  zipCode: Scalars['String'];
};

export type ClientDelivery = {
  __typename?: 'ClientDelivery';
  address: Scalars['String'];
  block: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  clientUserGroup: ClientUserGroup;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  default: Scalars['Boolean'];
  fax: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  prefecture: Prefecture;
  shipperName?: Maybe<Scalars['String']>;
  tel: Scalars['String'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  zipCode: Scalars['String'];
};

export type ClientStorageFile = {
  __typename?: 'ClientStorageFile';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  originalPath?: Maybe<Scalars['String']>;
  thumbnailPath?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ClientStorageSummary = {
  __typename?: 'ClientStorageSummary';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  fileSizeSummary?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  maxStorageSize?: Maybe<Scalars['Int']>;
  storageRatio?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ClientUser = {
  __typename?: 'ClientUser';
  clientUserRole: ClientUserRole;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  rootUserGroups: Array<ClientUserGroup>;
  tel?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  userGroups: Array<ClientUserGroup>;
  username?: Maybe<Scalars['String']>;
};

export enum ClientUserAppRole {
  /** Staff */
  Staff = 'staff',
  /** 承認スタッフ */
  ApprovalStaff = 'approval_staff',
  /** 管理者 */
  Manager = 'manager'
}

export type ClientUserGroup = {
  __typename?: 'ClientUserGroup';
  applicationFlow?: Maybe<ClientApplicationFlow>;
  children: Array<ClientUserGroup>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  defaultContact?: Maybe<ClientContact>;
  defaultDelivery?: Maybe<ClientDelivery>;
  id: Scalars['ID'];
  members: Array<ClientUser>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  parent?: Maybe<ClientUserGroup>;
  products: Array<Product>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ClientUserGroupMembership = {
  __typename?: 'ClientUserGroupMembership';
  clientUser: ClientUser;
  clientUserGroup: ClientUserGroup;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ClientUserGroupProduct = {
  __typename?: 'ClientUserGroupProduct';
  applicationFlow?: Maybe<ClientApplicationFlow>;
  clientUserGroup: ClientUserGroup;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  product: Product;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum ClientUserRole {
  /** Staff */
  Staff = 'staff',
  /** 承認スタッフ */
  ApprovalStaff = 'approval_staff',
  /** 管理者 */
  Manager = 'manager',
  /** 全権管理者 */
  Admin = 'admin'
}

export enum CommentType {
  Normal = 'normal',
  Reject = 'reject'
}

/** Autogenerated input type of CopyOneOrder */
export type CopyOneOrderInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CopyOneOrder */
export type CopyOneOrderPayload = {
  __typename?: 'CopyOneOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum CopyrightPatternCode {
  Disney = 'disney',
  DisneyPixar = 'disney_pixar',
  WinnieThePooh = 'winnie_the_pooh',
  Lucasfilm = 'lucasfilm',
  Marvel = 'marvel'
}

/** Autogenerated input type of CreateAutoTypesetting */
export type CreateAutoTypesettingInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAutoTypesetting */
export type CreateAutoTypesettingPayload = {
  __typename?: 'CreateAutoTypesettingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderProduct?: Maybe<OrderProduct>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneClientApplicationFlow */
export type CreateOneClientApplicationFlowInput = {
  clientUserGroupId: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateOneClientApplicationFlowLevel */
export type CreateOneClientApplicationFlowLevelInput = {
  routeId: Scalars['ID'];
  level: Scalars['Int'];
  groupApproverIds?: Maybe<Array<Scalars['ID']>>;
  userApproverIds?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneClientApplicationFlowLevel */
export type CreateOneClientApplicationFlowLevelPayload = {
  __typename?: 'CreateOneClientApplicationFlowLevelPayload';
  clientApplicationFlowLevel?: Maybe<ClientApplicationFlowLevel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of CreateOneClientApplicationFlow */
export type CreateOneClientApplicationFlowPayload = {
  __typename?: 'CreateOneClientApplicationFlowPayload';
  clientApplicationFlow?: Maybe<ClientApplicationFlow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneClientApplicationFlowRoute */
export type CreateOneClientApplicationFlowRouteInput = {
  flowId: Scalars['ID'];
  condLowerPrice?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneClientApplicationFlowRoute */
export type CreateOneClientApplicationFlowRoutePayload = {
  __typename?: 'CreateOneClientApplicationFlowRoutePayload';
  clientApplicationFlowRoute?: Maybe<ClientApplicationFlowRoute>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneClientContact */
export type CreateOneClientContactInput = {
  clientUserGroupId: Scalars['ID'];
  name: Scalars['String'];
  zipCode: Scalars['String'];
  prefecture: Prefecture;
  city: Scalars['String'];
  block: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  tel: Scalars['String'];
  fax: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneClientContact */
export type CreateOneClientContactPayload = {
  __typename?: 'CreateOneClientContactPayload';
  clientContact?: Maybe<ClientContact>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneClientDelivery */
export type CreateOneClientDeliveryInput = {
  clientUserGroupId: Scalars['ID'];
  name: Scalars['String'];
  zipCode: Scalars['String'];
  prefecture: Prefecture;
  city: Scalars['String'];
  block: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  tel: Scalars['String'];
  fax: Scalars['String'];
  shipperName?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneClientDelivery */
export type CreateOneClientDeliveryPayload = {
  __typename?: 'CreateOneClientDeliveryPayload';
  clientDelivery?: Maybe<ClientDelivery>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneClientUserGroup */
export type CreateOneClientUserGroupInput = {
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  applicationFlowId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateOneClientUserGroupMembership */
export type CreateOneClientUserGroupMembershipInput = {
  clientUserGroupId: Scalars['ID'];
  clientUserId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneClientUserGroupMembership */
export type CreateOneClientUserGroupMembershipPayload = {
  __typename?: 'CreateOneClientUserGroupMembershipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUserGroupMembership?: Maybe<ClientUserGroupMembership>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of CreateOneClientUserGroup */
export type CreateOneClientUserGroupPayload = {
  __typename?: 'CreateOneClientUserGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUserGroup?: Maybe<ClientUserGroup>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneClientUserGroupProduct */
export type CreateOneClientUserGroupProductInput = {
  clientUserGroupId: Scalars['ID'];
  productId: Scalars['ID'];
  applicationFlowId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneClientUserGroupProduct */
export type CreateOneClientUserGroupProductPayload = {
  __typename?: 'CreateOneClientUserGroupProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUserGroupProduct?: Maybe<ClientUserGroupProduct>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneClientUser */
export type CreateOneClientUserInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  clientUserRole?: Maybe<ClientUserAppRole>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneClientUser */
export type CreateOneClientUserPayload = {
  __typename?: 'CreateOneClientUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUser?: Maybe<ClientUser>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneOrderComment */
export type CreateOneOrderCommentInput = {
  comment: Scalars['String'];
  orderId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneOrderComment */
export type CreateOneOrderCommentPayload = {
  __typename?: 'CreateOneOrderCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderComment?: Maybe<OrderComment>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneOrderContact */
export type CreateOneOrderContactInput = {
  orderId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  city?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneOrderContact */
export type CreateOneOrderContactPayload = {
  __typename?: 'CreateOneOrderContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderContact?: Maybe<OrderContact>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneOrderDelivery */
export type CreateOneOrderDeliveryInput = {
  orderId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  city?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  shipperName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneOrderDelivery */
export type CreateOneOrderDeliveryPayload = {
  __typename?: 'CreateOneOrderDeliveryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderDelivery?: Maybe<OrderDelivery>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneOrder */
export type CreateOneOrderInput = {
  clientUserGroupId: Scalars['ID'];
  productPriceId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneOrder */
export type CreateOneOrderPayload = {
  __typename?: 'CreateOneOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneTypesettingParamMaterial */
export type CreateOneTypesettingParamMaterialInput = {
  typesettingParamId: Scalars['ID'];
  materialHeading?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialValue?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneTypesettingParamMaterial */
export type CreateOneTypesettingParamMaterialPayload = {
  __typename?: 'CreateOneTypesettingParamMaterialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamMaterial?: Maybe<TypesettingParamMaterial>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneTypesettingParamSize */
export type CreateOneTypesettingParamSizeInput = {
  typesettingParamId: Scalars['ID'];
  sizeComponentName?: Maybe<Scalars['String']>;
  sizeComponentValue?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneTypesettingParamSize */
export type CreateOneTypesettingParamSizePayload = {
  __typename?: 'CreateOneTypesettingParamSizePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamSize?: Maybe<TypesettingParamSize>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateOneTypesettingParamWarning */
export type CreateOneTypesettingParamWarningInput = {
  typesettingParamId: Scalars['ID'];
  warningHeading?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOneTypesettingParamWarning */
export type CreateOneTypesettingParamWarningPayload = {
  __typename?: 'CreateOneTypesettingParamWarningPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamWarning?: Maybe<TypesettingParamWarning>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreatePassword */
export type CreatePasswordInput = {
  email: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePassword */
export type CreatePasswordPayload = {
  __typename?: 'CreatePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUser: ClientUser;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreateSession */
export type CreateSessionInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateSession */
export type CreateSessionPayload = {
  __typename?: 'CreateSessionPayload';
  authToken?: Maybe<AuthToken>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type DeliveryRecord = {
  __typename?: 'DeliveryRecord';
  adminUser: AdminUser;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryRecordOrders: Array<DeliveryRecordOrder>;
  id: Scalars['ID'];
  shippedAt: Scalars['ISO8601DateTime'];
  shippedNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type DeliveryRecordOrder = {
  __typename?: 'DeliveryRecordOrder';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryRecord: DeliveryRecord;
  id: Scalars['ID'];
  orderDelivery: OrderDelivery;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum DeliveryStatusType {
  Wait = 'wait',
  Delivered = 'delivered'
}

export enum DesignPatternCode {
  DefaultDesign = 'default_design',
  StarWarsDesign = 'star_wars_design',
  MarvelDesign = 'marvel_design'
}

export enum DownloadFileFormat {
  Csv = 'csv',
  Xlsx = 'xlsx'
}

export type Holiday = {
  __typename?: 'Holiday';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  date: Scalars['ISO8601DateTime'];
  holidayType?: Maybe<HolidayType>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  viewOrder?: Maybe<Scalars['Int']>;
};

export enum HolidayType {
  /** 国民の休日 */
  National = 'national',
  /** 会社の休日 */
  Company = 'company',
  /** その他 */
  Other = 'other'
}


export type ImpositionProperty = {
  __typename?: 'ImpositionProperty';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type InnerCartonParam = {
  __typename?: 'InnerCartonParam';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  janCode?: Maybe<Scalars['String']>;
  orderProduct: OrderProduct;
  productName?: Maybe<Scalars['String']>;
  quantityPerInnerCartonText?: Maybe<Scalars['String']>;
  taxIncludedPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type MetaEndpoint = {
  __typename?: 'MetaEndpoint';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  approveManyOrderApprovalRecord?: Maybe<ApproveManyOrderApprovalRecordPayload>;
  cancelOrder?: Maybe<CancelOrderPayload>;
  copyOneOrder?: Maybe<CopyOneOrderPayload>;
  createAutoTypesetting?: Maybe<CreateAutoTypesettingPayload>;
  createOneClientApplicationFlow?: Maybe<CreateOneClientApplicationFlowPayload>;
  createOneClientApplicationFlowLevel?: Maybe<CreateOneClientApplicationFlowLevelPayload>;
  createOneClientApplicationFlowRoute?: Maybe<CreateOneClientApplicationFlowRoutePayload>;
  createOneClientContact?: Maybe<CreateOneClientContactPayload>;
  createOneClientDelivery?: Maybe<CreateOneClientDeliveryPayload>;
  createOneClientUser?: Maybe<CreateOneClientUserPayload>;
  createOneClientUserGroup?: Maybe<CreateOneClientUserGroupPayload>;
  createOneClientUserGroupMembership?: Maybe<CreateOneClientUserGroupMembershipPayload>;
  createOneClientUserGroupProduct?: Maybe<CreateOneClientUserGroupProductPayload>;
  createOneOrder?: Maybe<CreateOneOrderPayload>;
  createOneOrderComment?: Maybe<CreateOneOrderCommentPayload>;
  createOneOrderContact?: Maybe<CreateOneOrderContactPayload>;
  createOneOrderDelivery?: Maybe<CreateOneOrderDeliveryPayload>;
  createOneTypesettingParamMaterial?: Maybe<CreateOneTypesettingParamMaterialPayload>;
  createOneTypesettingParamSize?: Maybe<CreateOneTypesettingParamSizePayload>;
  createOneTypesettingParamWarning?: Maybe<CreateOneTypesettingParamWarningPayload>;
  createPassword?: Maybe<CreatePasswordPayload>;
  createSession?: Maybe<CreateSessionPayload>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  rejectOrderApprovalRecord?: Maybe<RejectOrderApprovalRecordPayload>;
  removeOneClientApplicationFlow?: Maybe<RemoveOneClientApplicationFlowPayload>;
  removeOneClientApplicationFlowLevel?: Maybe<RemoveOneClientApplicationFlowLevelPayload>;
  removeOneClientApplicationFlowRoute?: Maybe<RemoveOneClientApplicationFlowRoutePayload>;
  removeOneClientContact?: Maybe<RemoveOneClientContactPayload>;
  removeOneClientDelivery?: Maybe<RemoveOneClientDeliveryPayload>;
  removeOneClientStorageFile?: Maybe<RemoveOneClientStorageFilePayload>;
  removeOneClientUser?: Maybe<RemoveOneClientUserPayload>;
  removeOneClientUserGroup?: Maybe<RemoveOneClientUserGroupPayload>;
  removeOneClientUserGroupMembership?: Maybe<RemoveOneClientUserGroupMembershipPayload>;
  removeOneClientUserGroupProduct?: Maybe<RemoveOneClientUserGroupProductPayload>;
  removeOneOrder?: Maybe<RemoveOneOrderPayload>;
  removeOneOrderCommentFile?: Maybe<RemoveOneOrderCommentFilePayload>;
  removeOneOrderContact?: Maybe<RemoveOneOrderContactPayload>;
  removeOneOrderDelivery?: Maybe<RemoveOneOrderDeliveryPayload>;
  removeOneOrderProductFile?: Maybe<RemoveOneOrderProductFilePayload>;
  removeOneTypesettingParamMaterial?: Maybe<RemoveOneTypesettingParamMaterialPayload>;
  removeOneTypesettingParamSize?: Maybe<RemoveOneTypesettingParamSizePayload>;
  removeOneTypesettingParamWarning?: Maybe<RemoveOneTypesettingParamWarningPayload>;
  reorderOrder?: Maybe<ReorderOrderPayload>;
  updateAutoTypesetting?: Maybe<UpdateAutoTypesettingPayload>;
  updateOneClientApplicationFlow?: Maybe<UpdateOneClientApplicationFlowPayload>;
  updateOneClientApplicationFlowLevel?: Maybe<UpdateOneClientApplicationFlowLevelPayload>;
  updateOneClientApplicationFlowRoute?: Maybe<UpdateOneClientApplicationFlowRoutePayload>;
  updateOneClientContact?: Maybe<UpdateOneClientContactPayload>;
  updateOneClientDelivery?: Maybe<UpdateOneClientDeliveryPayload>;
  updateOneClientStorageFile?: Maybe<UpdateOneClientStorageFilePayload>;
  updateOneClientUser?: Maybe<UpdateOneClientUserPayload>;
  updateOneClientUserGroup?: Maybe<UpdateOneClientUserGroupPayload>;
  updateOneClientUserGroupProduct?: Maybe<UpdateOneClientUserGroupProductPayload>;
  updateOneInnerCartonParam?: Maybe<UpdateOneInnerCartonParamPayload>;
  updateOneOrder?: Maybe<UpdateOneOrderPayload>;
  updateOneOrderContact?: Maybe<UpdateOneOrderContactPayload>;
  updateOneOrderDelivery?: Maybe<UpdateOneOrderDeliveryPayload>;
  updateOneOuterCartonParam?: Maybe<UpdateOneOuterCartonParamPayload>;
  updateOnePriceLabelParam?: Maybe<UpdateOnePriceLabelParamPayload>;
  updateOneTypesettingParam?: Maybe<UpdateOneTypesettingParamPayload>;
  updateOneTypesettingParamMaterial?: Maybe<UpdateOneTypesettingParamMaterialPayload>;
  updateOneTypesettingParamSize?: Maybe<UpdateOneTypesettingParamSizePayload>;
  updateOneTypesettingParamWarning?: Maybe<UpdateOneTypesettingParamWarningPayload>;
  updatePassword?: Maybe<UpdatePasswordPayload>;
  updateSession?: Maybe<UpdateSessionPayload>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};


export type MutationApproveManyOrderApprovalRecordArgs = {
  input: ApproveManyOrderApprovalRecordInput;
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationCopyOneOrderArgs = {
  input: CopyOneOrderInput;
};


export type MutationCreateAutoTypesettingArgs = {
  input: CreateAutoTypesettingInput;
};


export type MutationCreateOneClientApplicationFlowArgs = {
  input: CreateOneClientApplicationFlowInput;
};


export type MutationCreateOneClientApplicationFlowLevelArgs = {
  input: CreateOneClientApplicationFlowLevelInput;
};


export type MutationCreateOneClientApplicationFlowRouteArgs = {
  input: CreateOneClientApplicationFlowRouteInput;
};


export type MutationCreateOneClientContactArgs = {
  input: CreateOneClientContactInput;
};


export type MutationCreateOneClientDeliveryArgs = {
  input: CreateOneClientDeliveryInput;
};


export type MutationCreateOneClientUserArgs = {
  input: CreateOneClientUserInput;
};


export type MutationCreateOneClientUserGroupArgs = {
  input: CreateOneClientUserGroupInput;
};


export type MutationCreateOneClientUserGroupMembershipArgs = {
  input: CreateOneClientUserGroupMembershipInput;
};


export type MutationCreateOneClientUserGroupProductArgs = {
  input: CreateOneClientUserGroupProductInput;
};


export type MutationCreateOneOrderArgs = {
  input: CreateOneOrderInput;
};


export type MutationCreateOneOrderCommentArgs = {
  input: CreateOneOrderCommentInput;
};


export type MutationCreateOneOrderContactArgs = {
  input: CreateOneOrderContactInput;
};


export type MutationCreateOneOrderDeliveryArgs = {
  input: CreateOneOrderDeliveryInput;
};


export type MutationCreateOneTypesettingParamMaterialArgs = {
  input: CreateOneTypesettingParamMaterialInput;
};


export type MutationCreateOneTypesettingParamSizeArgs = {
  input: CreateOneTypesettingParamSizeInput;
};


export type MutationCreateOneTypesettingParamWarningArgs = {
  input: CreateOneTypesettingParamWarningInput;
};


export type MutationCreatePasswordArgs = {
  input: CreatePasswordInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationRejectOrderApprovalRecordArgs = {
  input: RejectOrderApprovalRecordInput;
};


export type MutationRemoveOneClientApplicationFlowArgs = {
  input: RemoveOneClientApplicationFlowInput;
};


export type MutationRemoveOneClientApplicationFlowLevelArgs = {
  input: RemoveOneClientApplicationFlowLevelInput;
};


export type MutationRemoveOneClientApplicationFlowRouteArgs = {
  input: RemoveOneClientApplicationFlowRouteInput;
};


export type MutationRemoveOneClientContactArgs = {
  input: RemoveOneClientContactInput;
};


export type MutationRemoveOneClientDeliveryArgs = {
  input: RemoveOneClientDeliveryInput;
};


export type MutationRemoveOneClientStorageFileArgs = {
  input: RemoveOneClientStorageFileInput;
};


export type MutationRemoveOneClientUserArgs = {
  input: RemoveOneClientUserInput;
};


export type MutationRemoveOneClientUserGroupArgs = {
  input: RemoveOneClientUserGroupInput;
};


export type MutationRemoveOneClientUserGroupMembershipArgs = {
  input: RemoveOneClientUserGroupMembershipInput;
};


export type MutationRemoveOneClientUserGroupProductArgs = {
  input: RemoveOneClientUserGroupProductInput;
};


export type MutationRemoveOneOrderArgs = {
  input: RemoveOneOrderInput;
};


export type MutationRemoveOneOrderCommentFileArgs = {
  input: RemoveOneOrderCommentFileInput;
};


export type MutationRemoveOneOrderContactArgs = {
  input: RemoveOneOrderContactInput;
};


export type MutationRemoveOneOrderDeliveryArgs = {
  input: RemoveOneOrderDeliveryInput;
};


export type MutationRemoveOneOrderProductFileArgs = {
  input: RemoveOneOrderProductFileInput;
};


export type MutationRemoveOneTypesettingParamMaterialArgs = {
  input: RemoveOneTypesettingParamMaterialInput;
};


export type MutationRemoveOneTypesettingParamSizeArgs = {
  input: RemoveOneTypesettingParamSizeInput;
};


export type MutationRemoveOneTypesettingParamWarningArgs = {
  input: RemoveOneTypesettingParamWarningInput;
};


export type MutationReorderOrderArgs = {
  input: ReorderOrderInput;
};


export type MutationUpdateAutoTypesettingArgs = {
  input: UpdateAutoTypesettingInput;
};


export type MutationUpdateOneClientApplicationFlowArgs = {
  input: UpdateOneClientApplicationFlowInput;
};


export type MutationUpdateOneClientApplicationFlowLevelArgs = {
  input: UpdateOneClientApplicationFlowLevelInput;
};


export type MutationUpdateOneClientApplicationFlowRouteArgs = {
  input: UpdateOneClientApplicationFlowRouteInput;
};


export type MutationUpdateOneClientContactArgs = {
  input: UpdateOneClientContactInput;
};


export type MutationUpdateOneClientDeliveryArgs = {
  input: UpdateOneClientDeliveryInput;
};


export type MutationUpdateOneClientStorageFileArgs = {
  input: UpdateOneClientStorageFileInput;
};


export type MutationUpdateOneClientUserArgs = {
  input: UpdateOneClientUserInput;
};


export type MutationUpdateOneClientUserGroupArgs = {
  input: UpdateOneClientUserGroupInput;
};


export type MutationUpdateOneClientUserGroupProductArgs = {
  input: UpdateOneClientUserGroupProductInput;
};


export type MutationUpdateOneInnerCartonParamArgs = {
  input: UpdateOneInnerCartonParamInput;
};


export type MutationUpdateOneOrderArgs = {
  input: UpdateOneOrderInput;
};


export type MutationUpdateOneOrderContactArgs = {
  input: UpdateOneOrderContactInput;
};


export type MutationUpdateOneOrderDeliveryArgs = {
  input: UpdateOneOrderDeliveryInput;
};


export type MutationUpdateOneOuterCartonParamArgs = {
  input: UpdateOneOuterCartonParamInput;
};


export type MutationUpdateOnePriceLabelParamArgs = {
  input: UpdateOnePriceLabelParamInput;
};


export type MutationUpdateOneTypesettingParamArgs = {
  input: UpdateOneTypesettingParamInput;
};


export type MutationUpdateOneTypesettingParamMaterialArgs = {
  input: UpdateOneTypesettingParamMaterialInput;
};


export type MutationUpdateOneTypesettingParamSizeArgs = {
  input: UpdateOneTypesettingParamSizeInput;
};


export type MutationUpdateOneTypesettingParamWarningArgs = {
  input: UpdateOneTypesettingParamWarningInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};

export type Notification = {
  __typename?: 'Notification';
  adminUser: AdminUser;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Int'];
  applicationStatus: ApplicationStatusType;
  children: Array<Order>;
  clientUser: ClientUser;
  clientUserGroup: ClientUserGroup;
  comments: Array<OrderComment>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  delivered: Scalars['Boolean'];
  deliveryAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryStatus: DeliveryStatusType;
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  orderContacts?: Maybe<Array<OrderContact>>;
  orderDeliveries?: Maybe<Array<OrderDelivery>>;
  orderNo?: Maybe<Scalars['String']>;
  orderProduct: OrderProduct;
  orderStatus?: Maybe<OrderStatus>;
  orderedAt?: Maybe<Scalars['ISO8601DateTime']>;
  parent?: Maybe<Order>;
  price: Scalars['Int'];
  product: Product;
  setAmount: Scalars['Int'];
  shippingFee: Scalars['Int'];
  suppliers?: Maybe<Array<Supplier>>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  uploadStatus: UploadStatusType;
  uploaded: Scalars['Boolean'];
};

export type OrderApprovalRecord = {
  __typename?: 'OrderApprovalRecord';
  approvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  approvedBy?: Maybe<ClientUser>;
  approvers: Array<ClientUser>;
  children: Array<OrderApprovalRecord>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  level: Scalars['Int'];
  order: Order;
  parent?: Maybe<OrderApprovalRecord>;
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']>;
  rejectedBy?: Maybe<ClientUser>;
  rejectedMesage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type OrderComment = {
  __typename?: 'OrderComment';
  comment: Scalars['String'];
  commentType?: Maybe<CommentType>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  files?: Maybe<Array<OrderCommentFile>>;
  id: Scalars['ID'];
  order: Order;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  username: Scalars['String'];
};

export type OrderCommentFile = {
  __typename?: 'OrderCommentFile';
  comment: OrderComment;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mimeType?: Maybe<Scalars['String']>;
  originalPath?: Maybe<Scalars['String']>;
  thumbnailPath?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type OrderContact = {
  __typename?: 'OrderContact';
  address?: Maybe<Scalars['String']>;
  block: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  fax: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Order;
  prefecture: Prefecture;
  tel: Scalars['String'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  zipCode: Scalars['String'];
};

export type OrderDelivery = {
  __typename?: 'OrderDelivery';
  address?: Maybe<Scalars['String']>;
  allDeliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
  block: Scalars['String'];
  building?: Maybe<Scalars['String']>;
  children: Array<OrderDelivery>;
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryRecord?: Maybe<DeliveryRecord>;
  fax: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Order;
  parent?: Maybe<OrderDelivery>;
  prefecture: Prefecture;
  quantity: Scalars['Int'];
  shipperName?: Maybe<Scalars['String']>;
  tel: Scalars['String'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  zipCode: Scalars['String'];
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  basicDueDate?: Maybe<Scalars['Int']>;
  canDownload: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  files: Array<OrderProductFile>;
  id: Scalars['ID'];
  imposition?: Maybe<OrderProductFile>;
  innerCartonParam?: Maybe<InnerCartonParam>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: Order;
  outerCartonParam?: Maybe<OuterCartonParam>;
  price: Scalars['Int'];
  priceLabelParam?: Maybe<PriceLabelParam>;
  product: Product;
  productPrice?: Maybe<ProductPrice>;
  productType?: Maybe<ProductType>;
  quantity: Scalars['Int'];
  suppliers?: Maybe<Array<Supplier>>;
  typesettingParam?: Maybe<TypesettingParam>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type OrderProductFile = {
  __typename?: 'OrderProductFile';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdBy?: Maybe<ClientUser>;
  disabled?: Maybe<Scalars['Boolean']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mimeType?: Maybe<Scalars['String']>;
  order: Order;
  originalPath?: Maybe<Scalars['String']>;
  previewPath?: Maybe<Scalars['String']>;
  thumbnailPath?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  disable: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  orderStatusType?: Maybe<OrderStatusType>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  viewOrder: Scalars['Int'];
};

export enum OrderStatusType {
  Ordered = 'ordered',
  Production = 'production',
  Produced = 'produced'
}

export type OuterCartonParam = {
  __typename?: 'OuterCartonParam';
  block?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  janCode?: Maybe<Scalars['String']>;
  orderProduct: OrderProduct;
  poNumber?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  productName?: Maybe<Scalars['String']>;
  quantityPerCartonText?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  taxIncludedPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export enum Prefecture {
  Hokkaido = 'hokkaido',
  Aomori = 'aomori',
  Iwate = 'iwate',
  Miyagi = 'miyagi',
  Akita = 'akita',
  Yamagata = 'yamagata',
  Fukushima = 'fukushima',
  Ibaraki = 'ibaraki',
  Tochigi = 'tochigi',
  Gunma = 'gunma',
  Saitama = 'saitama',
  Chiba = 'chiba',
  Tokyo = 'tokyo',
  Kanagawa = 'kanagawa',
  Niigata = 'niigata',
  Toyama = 'toyama',
  Ishikawa = 'ishikawa',
  Fukui = 'fukui',
  Yamanashi = 'yamanashi',
  Nagano = 'nagano',
  Gifu = 'gifu',
  Shizuoka = 'shizuoka',
  Aichi = 'aichi',
  Mie = 'mie',
  Shiga = 'shiga',
  Kyoto = 'kyoto',
  Osaka = 'osaka',
  Hyogo = 'hyogo',
  Nara = 'nara',
  Wakayama = 'wakayama',
  Tottori = 'tottori',
  Shimane = 'shimane',
  Okayama = 'okayama',
  Hiroshima = 'hiroshima',
  Yamaguchi = 'yamaguchi',
  Tokushima = 'tokushima',
  Kagawa = 'kagawa',
  Ehime = 'ehime',
  Kochi = 'kochi',
  Fukuoka = 'fukuoka',
  Saga = 'saga',
  Nagasaki = 'nagasaki',
  Kumamoto = 'kumamoto',
  Oita = 'oita',
  Miyazaki = 'miyazaki',
  Kagoshima = 'kagoshima',
  Okinawa = 'okinawa'
}

export type PriceLabelParam = {
  __typename?: 'PriceLabelParam';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  janCode?: Maybe<Scalars['String']>;
  orderProduct: OrderProduct;
  taxIncludedPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum PriceType {
  Table = 'table',
  Unit = 'unit'
}

export type Product = {
  __typename?: 'Product';
  arrivalDate?: Maybe<Scalars['ISO8601DateTime']>;
  backCatalanaLay?: Maybe<CatalanaLay>;
  basicDueDate?: Maybe<Scalars['Int']>;
  canDownload: Scalars['Boolean'];
  catalanaLay?: Maybe<CatalanaLay>;
  category?: Maybe<ProductCategory>;
  clientUserGroups: Array<ClientUserGroup>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  designPatternCode?: Maybe<DesignPatternCode>;
  disable: Scalars['Boolean'];
  downloaders: Array<Supplier>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  files: Array<ProductFile>;
  hasMinPrice?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  impositionProperty?: Maybe<ImpositionProperty>;
  mimeType?: Maybe<Scalars['String']>;
  minLot?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  originalPath?: Maybe<Scalars['String']>;
  priceType: PriceType;
  prices: Array<ProductPrice>;
  productType?: Maybe<ProductType>;
  quantityPerBox?: Maybe<Scalars['Int']>;
  suppliers?: Maybe<Array<Supplier>>;
  tags: Array<ProductTag>;
  thumbnailPath?: Maybe<Scalars['String']>;
  unitBasicDueDate?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  disable: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ProductFile = {
  __typename?: 'ProductFile';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mimeType?: Maybe<Scalars['String']>;
  originalPath?: Maybe<Scalars['String']>;
  thumbnailPath?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  basicDueDate?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  price?: Maybe<Scalars['Int']>;
  product: Product;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ProductTag = {
  __typename?: 'ProductTag';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  disable: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum ProductType {
  Normal = 'normal',
  Draft = 'draft',
  TypesettingTag = 'typesetting_tag',
  OuterCartonLabel = 'outer_carton_label',
  InnerCartonLabel = 'inner_carton_label',
  PriceLabel = 'price_label'
}

export type Query = {
  __typename?: 'Query';
  clientApplicationFlow: ClientApplicationFlow;
  clientApplicationFlowLevel: ClientApplicationFlowLevel;
  clientApplicationFlowLevels: Array<ClientApplicationFlowLevel>;
  clientApplicationFlowRoute: ClientApplicationFlowRoute;
  clientApplicationFlowRoutes: Array<ClientApplicationFlowRoute>;
  clientApplicationFlows: Array<ClientApplicationFlow>;
  clientContact: ClientContact;
  clientContacts: Array<ClientContact>;
  clientDeliveries: Array<ClientDelivery>;
  clientDelivery: ClientDelivery;
  clientStorageFile: ClientStorageFile;
  clientStorageFiles: Array<ClientStorageFile>;
  clientStorageSummary: ClientStorageSummary;
  clientUser: ClientUser;
  clientUserGroup: ClientUserGroup;
  clientUserGroupMemberships: Array<ClientUserGroupMembership>;
  clientUserGroupProducts: Array<ClientUserGroupProduct>;
  clientUserGroups: Array<ClientUserGroup>;
  clientUsers: Array<ClientUser>;
  count: Scalars['Int'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  exportEndpoint: MetaEndpoint;
  holidays: Array<Holiday>;
  id: Scalars['ID'];
  importEndpoint: MetaEndpoint;
  innerCartonParam: InnerCartonParam;
  innerCartonParams: Array<InnerCartonParam>;
  notification: Notification;
  notifications: Array<Notification>;
  order: Order;
  orderApprovalRecord: OrderApprovalRecord;
  orderApprovalRecords: Array<OrderApprovalRecord>;
  orderComments: Array<OrderComment>;
  orderProductFile: OrderProductFile;
  orderProductFiles: Array<OrderProductFile>;
  orderStatuses: Array<OrderStatus>;
  orders: Array<Order>;
  ordersAmount: Scalars['Int'];
  ordersTotal: Scalars['Int'];
  outerCartonParam: OuterCartonParam;
  outerCartonParams: Array<OuterCartonParam>;
  priceLabelParam: PriceLabelParam;
  priceLabelParams: Array<PriceLabelParam>;
  product: Product;
  productCategories: Array<ProductCategory>;
  productCategory: ProductCategory;
  productPrice: ProductPrice;
  productTags: Array<ProductTag>;
  products: Array<Product>;
  session: Session;
  status: Status;
  supplier: Supplier;
  suppliers: Array<Supplier>;
  typesettingParam: TypesettingParam;
  typesettingParams: Array<TypesettingParam>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  uploadEndpoint: MetaEndpoint;
};


export type QueryClientApplicationFlowArgs = {
  id: Scalars['ID'];
};


export type QueryClientApplicationFlowLevelArgs = {
  id: Scalars['ID'];
};


export type QueryClientApplicationFlowLevelsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  routeId: Scalars['ID'];
};


export type QueryClientApplicationFlowRouteArgs = {
  id: Scalars['ID'];
};


export type QueryClientApplicationFlowRoutesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  flowId: Scalars['ID'];
};


export type QueryClientApplicationFlowsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  clientUserGroupId: Scalars['ID'];
};


export type QueryClientContactArgs = {
  id: Scalars['ID'];
};


export type QueryClientContactsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  clientUserGroupId?: Maybe<Scalars['ID']>;
};


export type QueryClientDeliveriesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  clientUserGroupId?: Maybe<Scalars['ID']>;
};


export type QueryClientDeliveryArgs = {
  id: Scalars['ID'];
};


export type QueryClientStorageFileArgs = {
  id: Scalars['ID'];
};


export type QueryClientStorageFilesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  clientUserGroupId?: Maybe<Scalars['ID']>;
};


export type QueryClientUserArgs = {
  id: Scalars['ID'];
};


export type QueryClientUserGroupArgs = {
  id: Scalars['ID'];
};


export type QueryClientUserGroupMembershipsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  clientUserGroupId: Scalars['ID'];
};


export type QueryClientUserGroupProductsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  clientUserGroupId: Scalars['ID'];
};


export type QueryClientUserGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryClientUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountArgs = {
  name: Scalars['String'];
};


export type QueryExportEndpointArgs = {
  fileFormat: DownloadFileFormat;
  resourceName: Scalars['String'];
};


export type QueryHolidaysArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryImportEndpointArgs = {
  fileFormat: DownloadFileFormat;
  resourceName: Scalars['String'];
};


export type QueryInnerCartonParamArgs = {
  id: Scalars['ID'];
};


export type QueryInnerCartonParamsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderApprovalRecordArgs = {
  id: Scalars['ID'];
};


export type QueryOrderApprovalRecordsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  current?: Maybe<Scalars['Boolean']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryOrderCommentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderId: Scalars['ID'];
};


export type QueryOrderProductFileArgs = {
  id: Scalars['ID'];
};


export type QueryOrderProductFilesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderId: Scalars['ID'];
};


export type QueryOrderStatusesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryOrdersArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  clientUserId?: Maybe<Scalars['ID']>;
  draftOnly?: Maybe<Scalars['Boolean']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  orderedFrom?: Maybe<Scalars['ISO8601DateTime']>;
  orderedTo?: Maybe<Scalars['ISO8601DateTime']>;
  orderNo?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  orderStatusId?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Scalars['ID']>>;
  productCategoryId?: Maybe<Scalars['ID']>;
};


export type QueryOrdersAmountArgs = {
  clientUserId?: Maybe<Scalars['ID']>;
  draftOnly?: Maybe<Scalars['Boolean']>;
  orderedFrom?: Maybe<Scalars['ISO8601DateTime']>;
  orderedTo?: Maybe<Scalars['ISO8601DateTime']>;
  orderNo?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  orderStatusId?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Scalars['ID']>>;
  productCategoryId?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryOrdersTotalArgs = {
  clientUserId?: Maybe<Scalars['ID']>;
  draftOnly?: Maybe<Scalars['Boolean']>;
  orderedFrom?: Maybe<Scalars['ISO8601DateTime']>;
  orderedTo?: Maybe<Scalars['ISO8601DateTime']>;
  orderNo?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  orderStatusId?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Scalars['ID']>>;
  productCategoryId?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryOuterCartonParamArgs = {
  id: Scalars['ID'];
};


export type QueryOuterCartonParamsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryPriceLabelParamArgs = {
  id: Scalars['ID'];
};


export type QueryPriceLabelParamsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductCategoriesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProductCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryProductPriceArgs = {
  id: Scalars['ID'];
};


export type QueryProductTagsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProductsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  clientUserGroup?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Scalars['ID']>>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QuerySuppliersArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTypesettingParamArgs = {
  id: Scalars['ID'];
};


export type QueryTypesettingParamsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUploadEndpointArgs = {
  fileFormat: UploadFileFormat;
  resourceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of RejectOrderApprovalRecord */
export type RejectOrderApprovalRecordInput = {
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RejectOrderApprovalRecord */
export type RejectOrderApprovalRecordPayload = {
  __typename?: 'RejectOrderApprovalRecordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderApprovalRecord?: Maybe<OrderApprovalRecord>;
  orderComment?: Maybe<OrderComment>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneClientApplicationFlow */
export type RemoveOneClientApplicationFlowInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of RemoveOneClientApplicationFlowLevel */
export type RemoveOneClientApplicationFlowLevelInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneClientApplicationFlowLevel */
export type RemoveOneClientApplicationFlowLevelPayload = {
  __typename?: 'RemoveOneClientApplicationFlowLevelPayload';
  clientApplicationFlowLevel?: Maybe<ClientApplicationFlowLevel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of RemoveOneClientApplicationFlow */
export type RemoveOneClientApplicationFlowPayload = {
  __typename?: 'RemoveOneClientApplicationFlowPayload';
  clientApplicationFlow?: Maybe<ClientApplicationFlow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneClientApplicationFlowRoute */
export type RemoveOneClientApplicationFlowRouteInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneClientApplicationFlowRoute */
export type RemoveOneClientApplicationFlowRoutePayload = {
  __typename?: 'RemoveOneClientApplicationFlowRoutePayload';
  clientApplicationFlowRoute?: Maybe<ClientApplicationFlowRoute>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneClientContact */
export type RemoveOneClientContactInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneClientContact */
export type RemoveOneClientContactPayload = {
  __typename?: 'RemoveOneClientContactPayload';
  clientContact?: Maybe<ClientContact>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneClientDelivery */
export type RemoveOneClientDeliveryInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneClientDelivery */
export type RemoveOneClientDeliveryPayload = {
  __typename?: 'RemoveOneClientDeliveryPayload';
  clientDelivery?: Maybe<ClientDelivery>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneClientStorageFile */
export type RemoveOneClientStorageFileInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneClientStorageFile */
export type RemoveOneClientStorageFilePayload = {
  __typename?: 'RemoveOneClientStorageFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientStorageFile?: Maybe<ClientStorageFile>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneClientUserGroup */
export type RemoveOneClientUserGroupInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of RemoveOneClientUserGroupMembership */
export type RemoveOneClientUserGroupMembershipInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneClientUserGroupMembership */
export type RemoveOneClientUserGroupMembershipPayload = {
  __typename?: 'RemoveOneClientUserGroupMembershipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUserGroupMembership?: Maybe<ClientUserGroupMembership>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of RemoveOneClientUserGroup */
export type RemoveOneClientUserGroupPayload = {
  __typename?: 'RemoveOneClientUserGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUserGroup?: Maybe<ClientUserGroup>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneClientUserGroupProduct */
export type RemoveOneClientUserGroupProductInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneClientUserGroupProduct */
export type RemoveOneClientUserGroupProductPayload = {
  __typename?: 'RemoveOneClientUserGroupProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUserGroupProduct?: Maybe<ClientUserGroupProduct>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneClientUser */
export type RemoveOneClientUserInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneClientUser */
export type RemoveOneClientUserPayload = {
  __typename?: 'RemoveOneClientUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUser?: Maybe<ClientUser>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneOrderCommentFile */
export type RemoveOneOrderCommentFileInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneOrderCommentFile */
export type RemoveOneOrderCommentFilePayload = {
  __typename?: 'RemoveOneOrderCommentFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderCommentFile?: Maybe<OrderCommentFile>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneOrderContact */
export type RemoveOneOrderContactInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneOrderContact */
export type RemoveOneOrderContactPayload = {
  __typename?: 'RemoveOneOrderContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderContact?: Maybe<OrderContact>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneOrderDelivery */
export type RemoveOneOrderDeliveryInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneOrderDelivery */
export type RemoveOneOrderDeliveryPayload = {
  __typename?: 'RemoveOneOrderDeliveryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderDelivery?: Maybe<OrderDelivery>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneOrder */
export type RemoveOneOrderInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneOrder */
export type RemoveOneOrderPayload = {
  __typename?: 'RemoveOneOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneOrderProductFile */
export type RemoveOneOrderProductFileInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneOrderProductFile */
export type RemoveOneOrderProductFilePayload = {
  __typename?: 'RemoveOneOrderProductFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderProductFile?: Maybe<OrderProductFile>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneTypesettingParamMaterial */
export type RemoveOneTypesettingParamMaterialInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneTypesettingParamMaterial */
export type RemoveOneTypesettingParamMaterialPayload = {
  __typename?: 'RemoveOneTypesettingParamMaterialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamMaterial?: Maybe<TypesettingParamMaterial>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneTypesettingParamSize */
export type RemoveOneTypesettingParamSizeInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneTypesettingParamSize */
export type RemoveOneTypesettingParamSizePayload = {
  __typename?: 'RemoveOneTypesettingParamSizePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamSize?: Maybe<TypesettingParamSize>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of RemoveOneTypesettingParamWarning */
export type RemoveOneTypesettingParamWarningInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveOneTypesettingParamWarning */
export type RemoveOneTypesettingParamWarningPayload = {
  __typename?: 'RemoveOneTypesettingParamWarningPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamWarning?: Maybe<TypesettingParamWarning>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of ReorderOrder */
export type ReorderOrderInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ReorderOrder */
export type ReorderOrderPayload = {
  __typename?: 'ReorderOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Session = {
  __typename?: 'Session';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentUser: ClientUser;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Status = {
  __typename?: 'Status';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  stage: Scalars['String'];
  status: Scalars['String'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['String'];
};

export type Supplier = {
  __typename?: 'Supplier';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  downloadableProducts: Array<Product>;
  id: Scalars['ID'];
  members: Array<AdminUser>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  products: Array<Product>;
  supplierRole: SupplierRole;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum SupplierRole {
  /** 印刷業者 */
  Normal = 'normal',
  /** 配送業者 */
  Delivery = 'delivery'
}

export type TypesettingParam = {
  __typename?: 'TypesettingParam';
  copyrightPatternCode: CopyrightPatternCode;
  countryOfOrigin: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  factoryProductCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  janCode?: Maybe<Scalars['String']>;
  materialTableName?: Maybe<Scalars['String']>;
  needleDetectionPrintFlag: Scalars['Boolean'];
  orderProduct: OrderProduct;
  productName?: Maybe<Scalars['String']>;
  recyclingSymbolCode?: Maybe<Scalars['String']>;
  sizeName?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  targetAgeText: Scalars['String'];
  taxIncludedPrice?: Maybe<Scalars['Int']>;
  threeCircleMarkFlag: Scalars['Boolean'];
  trackingCode?: Maybe<Scalars['String']>;
  typesettingParamMaterials?: Maybe<Array<TypesettingParamMaterial>>;
  typesettingParamSizes?: Maybe<Array<TypesettingParamSize>>;
  typesettingParamWarnings?: Maybe<Array<TypesettingParamWarning>>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type TypesettingParamMaterial = {
  __typename?: 'TypesettingParamMaterial';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  materialHeading?: Maybe<Scalars['String']>;
  materialName: Scalars['String'];
  materialValue?: Maybe<Scalars['Float']>;
  typesettingParamId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type TypesettingParamMaterialInput = {
  id: Scalars['ID'];
  materialHeading?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialValue?: Maybe<Scalars['Float']>;
};

export type TypesettingParamSize = {
  __typename?: 'TypesettingParamSize';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  sizeComponentName?: Maybe<Scalars['String']>;
  sizeComponentValue: Scalars['String'];
  typesettingParamId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type TypesettingParamSizeInput = {
  id: Scalars['ID'];
  sizeComponentName?: Maybe<Scalars['String']>;
  sizeComponentValue?: Maybe<Scalars['String']>;
};

export type TypesettingParamWarning = {
  __typename?: 'TypesettingParamWarning';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  warning: Scalars['String'];
  warningHeading?: Maybe<Scalars['String']>;
};

export type TypesettingParamWarningInput = {
  id: Scalars['ID'];
  warningHeading?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAutoTypesetting */
export type UpdateAutoTypesettingInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAutoTypesetting */
export type UpdateAutoTypesettingPayload = {
  __typename?: 'UpdateAutoTypesettingPayload';
  accessToken?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  fileKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderProduct?: Maybe<OrderProduct>;
  sonbpUri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneClientApplicationFlow */
export type UpdateOneClientApplicationFlowInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateOneClientApplicationFlowLevel */
export type UpdateOneClientApplicationFlowLevelInput = {
  id: Scalars['ID'];
  level?: Maybe<Scalars['Int']>;
  groupApproverIds?: Maybe<Array<Scalars['ID']>>;
  userApproverIds?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneClientApplicationFlowLevel */
export type UpdateOneClientApplicationFlowLevelPayload = {
  __typename?: 'UpdateOneClientApplicationFlowLevelPayload';
  clientApplicationFlowLevel?: Maybe<ClientApplicationFlowLevel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of UpdateOneClientApplicationFlow */
export type UpdateOneClientApplicationFlowPayload = {
  __typename?: 'UpdateOneClientApplicationFlowPayload';
  clientApplicationFlow?: Maybe<ClientApplicationFlow>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneClientApplicationFlowRoute */
export type UpdateOneClientApplicationFlowRouteInput = {
  id: Scalars['ID'];
  condLowerPrice?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneClientApplicationFlowRoute */
export type UpdateOneClientApplicationFlowRoutePayload = {
  __typename?: 'UpdateOneClientApplicationFlowRoutePayload';
  clientApplicationFlowRoute?: Maybe<ClientApplicationFlowRoute>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneClientContact */
export type UpdateOneClientContactInput = {
  id: Scalars['ID'];
  clientUserGroupId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  city?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneClientContact */
export type UpdateOneClientContactPayload = {
  __typename?: 'UpdateOneClientContactPayload';
  clientContact?: Maybe<ClientContact>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneClientDelivery */
export type UpdateOneClientDeliveryInput = {
  id: Scalars['ID'];
  clientUserGroupId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  city?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  shipperName?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneClientDelivery */
export type UpdateOneClientDeliveryPayload = {
  __typename?: 'UpdateOneClientDeliveryPayload';
  clientDelivery?: Maybe<ClientDelivery>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneClientStorageFile */
export type UpdateOneClientStorageFileInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneClientStorageFile */
export type UpdateOneClientStorageFilePayload = {
  __typename?: 'UpdateOneClientStorageFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientStorageFile?: Maybe<ClientStorageFile>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneClientUserGroup */
export type UpdateOneClientUserGroupInput = {
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  defaultContactId?: Maybe<Scalars['ID']>;
  defaultDeliveryId?: Maybe<Scalars['ID']>;
  applicationFlowId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneClientUserGroup */
export type UpdateOneClientUserGroupPayload = {
  __typename?: 'UpdateOneClientUserGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUserGroup?: Maybe<ClientUserGroup>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneClientUserGroupProduct */
export type UpdateOneClientUserGroupProductInput = {
  id: Scalars['ID'];
  applicationFlowId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneClientUserGroupProduct */
export type UpdateOneClientUserGroupProductPayload = {
  __typename?: 'UpdateOneClientUserGroupProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUserGroupProduct?: Maybe<ClientUserGroupProduct>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneClientUser */
export type UpdateOneClientUserInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  clientUserRole?: Maybe<ClientUserAppRole>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneClientUser */
export type UpdateOneClientUserPayload = {
  __typename?: 'UpdateOneClientUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUser?: Maybe<ClientUser>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneInnerCartonParam */
export type UpdateOneInnerCartonParamInput = {
  id: Scalars['ID'];
  janCode?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  quantityPerInnerCartonText?: Maybe<Scalars['String']>;
  taxIncludedPrice?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneInnerCartonParam */
export type UpdateOneInnerCartonParamPayload = {
  __typename?: 'UpdateOneInnerCartonParamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  innerCartonParam?: Maybe<InnerCartonParam>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneOrderContact */
export type UpdateOneOrderContactInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  city?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneOrderContact */
export type UpdateOneOrderContactPayload = {
  __typename?: 'UpdateOneOrderContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderContact?: Maybe<OrderContact>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneOrderDelivery */
export type UpdateOneOrderDeliveryInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  city?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  shipperName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneOrderDelivery */
export type UpdateOneOrderDeliveryPayload = {
  __typename?: 'UpdateOneOrderDeliveryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  orderDelivery?: Maybe<OrderDelivery>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneOrder */
export type UpdateOneOrderInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
  productPriceId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneOrder */
export type UpdateOneOrderPayload = {
  __typename?: 'UpdateOneOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneOuterCartonParam */
export type UpdateOneOuterCartonParamInput = {
  id: Scalars['ID'];
  poNumber?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  taxIncludedPrice?: Maybe<Scalars['Int']>;
  quantityPerCartonText?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  city?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneOuterCartonParam */
export type UpdateOneOuterCartonParamPayload = {
  __typename?: 'UpdateOneOuterCartonParamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  outerCartonParam?: Maybe<OuterCartonParam>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOnePriceLabelParam */
export type UpdateOnePriceLabelParamInput = {
  id: Scalars['ID'];
  janCode?: Maybe<Scalars['String']>;
  taxIncludedPrice?: Maybe<Scalars['Int']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOnePriceLabelParam */
export type UpdateOnePriceLabelParamPayload = {
  __typename?: 'UpdateOnePriceLabelParamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  priceLabelParam?: Maybe<PriceLabelParam>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneTypesettingParam */
export type UpdateOneTypesettingParamInput = {
  id: Scalars['ID'];
  supplierName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  factoryProductCode?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  taxIncludedPrice?: Maybe<Scalars['Int']>;
  trackingCode?: Maybe<Scalars['String']>;
  targetAgeText?: Maybe<Scalars['String']>;
  needleDetectionPrintFlag?: Maybe<Scalars['Boolean']>;
  copyrightPatternCode?: Maybe<CopyrightPatternCode>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  recyclingSymbolCode?: Maybe<Scalars['String']>;
  threeCircleMarkFlag?: Maybe<Scalars['Boolean']>;
  sizeName?: Maybe<Scalars['String']>;
  typesettingParamSizes?: Maybe<Array<TypesettingParamSizeInput>>;
  typesettingParamMaterials?: Maybe<Array<TypesettingParamMaterialInput>>;
  typesettingParamWarnings?: Maybe<Array<TypesettingParamWarningInput>>;
  materialTableName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateOneTypesettingParamMaterial */
export type UpdateOneTypesettingParamMaterialInput = {
  id: Scalars['ID'];
  materialHeading?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialValue?: Maybe<Scalars['Float']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneTypesettingParamMaterial */
export type UpdateOneTypesettingParamMaterialPayload = {
  __typename?: 'UpdateOneTypesettingParamMaterialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamMaterial?: Maybe<TypesettingParamMaterial>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of UpdateOneTypesettingParam */
export type UpdateOneTypesettingParamPayload = {
  __typename?: 'UpdateOneTypesettingParamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParam?: Maybe<TypesettingParam>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneTypesettingParamSize */
export type UpdateOneTypesettingParamSizeInput = {
  id: Scalars['ID'];
  sizeComponentName?: Maybe<Scalars['String']>;
  sizeComponentValue?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneTypesettingParamSize */
export type UpdateOneTypesettingParamSizePayload = {
  __typename?: 'UpdateOneTypesettingParamSizePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamSize?: Maybe<TypesettingParamSize>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateOneTypesettingParamWarning */
export type UpdateOneTypesettingParamWarningInput = {
  id: Scalars['ID'];
  warningHeading?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOneTypesettingParamWarning */
export type UpdateOneTypesettingParamWarningPayload = {
  __typename?: 'UpdateOneTypesettingParamWarningPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  typesettingParamWarning?: Maybe<TypesettingParamWarning>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdatePassword */
export type UpdatePasswordInput = {
  passwordToken: Scalars['String'];
  password: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePassword */
export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientUser?: Maybe<ClientUser>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdateSession */
export type UpdateSessionInput = {
  refreshToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateSession */
export type UpdateSessionPayload = {
  __typename?: 'UpdateSessionPayload';
  authToken?: Maybe<AuthToken>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum UploadFileFormat {
  Ai = 'ai',
  Eps = 'eps',
  Psd = 'psd',
  Psb = 'psb',
  Jpg = 'jpg',
  Tif = 'tif',
  Gif = 'gif',
  Png = 'png',
  Bmp = 'bmp',
  Pdf = 'pdf',
  Indd = 'indd',
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xlsx = 'xlsx',
  Ppt = 'ppt',
  Pptx = 'pptx'
}

export enum UploadStatusType {
  Wait = 'wait',
  Uploaded = 'uploaded'
}

export type ClientApplicationFlowFragmentFragment = (
  { __typename?: 'ClientApplicationFlow' }
  & Pick<ClientApplicationFlow, 'id' | 'name' | 'default'>
);

export type ClientApplicationFlowLevelFragmentFragment = (
  { __typename?: 'ClientApplicationFlowLevel' }
  & Pick<ClientApplicationFlowLevel, 'id' | 'level'>
  & { userApprovers: Array<(
    { __typename?: 'ClientUser' }
    & Pick<ClientUser, 'id' | 'name'>
  )> }
);

export type ClientApplicationFlowRouteFragmentFragment = (
  { __typename?: 'ClientApplicationFlowRoute' }
  & Pick<ClientApplicationFlowRoute, 'id' | 'condLowerPrice'>
);

export type ClientContactFragmentFragment = (
  { __typename?: 'ClientContact' }
  & Pick<ClientContact, 'id' | 'name' | 'code' | 'zipCode' | 'city' | 'block' | 'building' | 'tel' | 'fax' | 'prefecture' | 'address' | 'default'>
);

export type ClientDeliveryFragmentFragment = (
  { __typename?: 'ClientDelivery' }
  & Pick<ClientDelivery, 'id' | 'name' | 'code' | 'zipCode' | 'city' | 'block' | 'building' | 'tel' | 'fax' | 'prefecture' | 'address' | 'default' | 'shipperName'>
);

export type ClientStorageFileFragmentFragment = (
  { __typename?: 'ClientStorageFile' }
  & Pick<ClientStorageFile, 'id' | 'name' | 'note' | 'fileName' | 'fileSize' | 'mimeType' | 'originalPath' | 'thumbnailPath'>
);

export type ClientStorageSummaryFragmentFragment = (
  { __typename?: 'ClientStorageSummary' }
  & Pick<ClientStorageSummary, 'fileSizeSummary' | 'maxStorageSize' | 'storageRatio'>
);

export type ClientUserFragmentFragment = (
  { __typename?: 'ClientUser' }
  & Pick<ClientUser, 'id' | 'name'>
);

export type ClientUserGroupFragmentFragment = (
  { __typename?: 'ClientUserGroup' }
  & Pick<ClientUserGroup, 'id' | 'name' | 'code' | 'note'>
);

export type HolidayFragmentFragment = (
  { __typename?: 'Holiday' }
  & Pick<Holiday, 'id' | 'code' | 'name' | 'note' | 'date' | 'holidayType'>
);

export type InnerCartonParamFragmentFragment = (
  { __typename?: 'InnerCartonParam' }
  & Pick<InnerCartonParam, 'id' | 'janCode' | 'productName' | 'quantityPerInnerCartonText' | 'taxIncludedPrice'>
  & { orderProduct: (
    { __typename?: 'OrderProduct' }
    & OrderProductFragmentFragment
  ) }
);

export type NotificationFragmentFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'title' | 'content' | 'publishedAt'>
);

export type OrderFragmentFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'orderNo' | 'deliveryAt' | 'orderedAt' | 'uploaded' | 'delivered' | 'price' | 'shippingFee' | 'amount' | 'setAmount' | 'applicationStatus' | 'uploadStatus' | 'deliveryStatus'>
  & { orderStatus?: Maybe<(
    { __typename?: 'OrderStatus' }
    & Pick<OrderStatus, 'id' | 'name' | 'viewOrder'>
  )>, parent?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'name'>
  )>, clientUserGroup: (
    { __typename?: 'ClientUserGroup' }
    & Pick<ClientUserGroup, 'id' | 'name'>
  ), clientUser: (
    { __typename?: 'ClientUser' }
    & Pick<ClientUser, 'id' | 'name'>
  ), orderProduct: (
    { __typename?: 'OrderProduct' }
    & Pick<OrderProduct, 'id' | 'name' | 'quantity' | 'price' | 'basicDueDate' | 'note' | 'productType' | 'canDownload'>
    & { productPrice?: Maybe<(
      { __typename?: 'ProductPrice' }
      & Pick<ProductPrice, 'id' | 'quantity' | 'price'>
    )>, files: Array<(
      { __typename?: 'OrderProductFile' }
      & OrderProductFileFragmentFragment
    )>, typesettingParam?: Maybe<(
      { __typename?: 'TypesettingParam' }
      & TypesettingParamFragmentFragment
    )>, outerCartonParam?: Maybe<(
      { __typename?: 'OuterCartonParam' }
      & OuterCartonParamFragmentFragment
    )>, innerCartonParam?: Maybe<(
      { __typename?: 'InnerCartonParam' }
      & InnerCartonParamFragmentFragment
    )>, priceLabelParam?: Maybe<(
      { __typename?: 'PriceLabelParam' }
      & PriceLabelParamFragmentFragment
    )> }
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'note' | 'productType' | 'priceType' | 'thumbnailPath' | 'originalPath' | 'mimeType' | 'canDownload'>
    & { prices: Array<(
      { __typename?: 'ProductPrice' }
      & Pick<ProductPrice, 'id' | 'quantity' | 'price' | 'basicDueDate'>
    )> }
  ), orderDeliveries?: Maybe<Array<(
    { __typename?: 'OrderDelivery' }
    & Pick<OrderDelivery, 'id' | 'name' | 'zipCode' | 'city' | 'block' | 'building' | 'tel' | 'fax' | 'prefecture' | 'address' | 'shipperName' | 'quantity'>
  )>>, orderContacts?: Maybe<Array<(
    { __typename?: 'OrderContact' }
    & Pick<OrderContact, 'id' | 'name' | 'zipCode' | 'city' | 'block' | 'building' | 'tel' | 'fax' | 'prefecture' | 'address'>
  )>> }
);

export type OrderApprovalRecordFragmentFragment = (
  { __typename?: 'OrderApprovalRecord' }
  & Pick<OrderApprovalRecord, 'id' | 'level' | 'approvedAt' | 'rejectedAt'>
  & { approvedBy?: Maybe<(
    { __typename?: 'ClientUser' }
    & Pick<ClientUser, 'id' | 'name'>
  )>, rejectedBy?: Maybe<(
    { __typename?: 'ClientUser' }
    & Pick<ClientUser, 'id' | 'name'>
  )>, order: (
    { __typename?: 'Order' }
    & OrderFragmentFragment
  ), parent?: Maybe<(
    { __typename?: 'OrderApprovalRecord' }
    & Pick<OrderApprovalRecord, 'id'>
  )> }
);

export type OrderCommentFragmentFragment = (
  { __typename?: 'OrderComment' }
  & Pick<OrderComment, 'id' | 'comment' | 'createdAt' | 'username' | 'commentType'>
  & { files?: Maybe<Array<(
    { __typename?: 'OrderCommentFile' }
    & Pick<OrderCommentFile, 'id' | 'fileName' | 'fileSize' | 'mimeType' | 'originalPath' | 'thumbnailPath'>
  )>> }
);

export type OrderCommentFileFragmentFragment = (
  { __typename?: 'OrderCommentFile' }
  & Pick<OrderCommentFile, 'id' | 'fileName' | 'fileSize' | 'mimeType' | 'originalPath' | 'thumbnailPath'>
);

export type OrderContactFragmentFragment = (
  { __typename?: 'OrderContact' }
  & Pick<OrderContact, 'id' | 'name' | 'zipCode' | 'city' | 'block' | 'building' | 'tel' | 'fax' | 'prefecture' | 'address'>
);

export type OrderDeliveryFragmentFragment = (
  { __typename?: 'OrderDelivery' }
  & Pick<OrderDelivery, 'id' | 'name' | 'zipCode' | 'city' | 'block' | 'building' | 'tel' | 'fax' | 'prefecture' | 'address' | 'quantity' | 'shipperName'>
);

export type OrderProductFragmentFragment = (
  { __typename?: 'OrderProduct' }
  & Pick<OrderProduct, 'id' | 'name' | 'quantity' | 'price' | 'basicDueDate' | 'note' | 'productType' | 'canDownload'>
  & { productPrice?: Maybe<(
    { __typename?: 'ProductPrice' }
    & Pick<ProductPrice, 'id' | 'quantity' | 'price'>
  )>, files: Array<(
    { __typename?: 'OrderProductFile' }
    & OrderProductFileFragmentFragment
  )>, typesettingParam?: Maybe<(
    { __typename?: 'TypesettingParam' }
    & Pick<TypesettingParam, 'id'>
  )>, outerCartonParam?: Maybe<(
    { __typename?: 'OuterCartonParam' }
    & Pick<OuterCartonParam, 'id'>
  )>, innerCartonParam?: Maybe<(
    { __typename?: 'InnerCartonParam' }
    & Pick<InnerCartonParam, 'id'>
  )> }
);

export type OrderProductFileFragmentFragment = (
  { __typename?: 'OrderProductFile' }
  & Pick<OrderProductFile, 'id' | 'fileName' | 'fileSize' | 'mimeType' | 'originalPath' | 'thumbnailPath' | 'previewPath' | 'createdAt' | 'updatedAt' | 'disabled'>
  & { createdBy?: Maybe<(
    { __typename?: 'ClientUser' }
    & Pick<ClientUser, 'id' | 'name'>
  )> }
);

export type OrderStatusFragmentFragment = (
  { __typename?: 'OrderStatus' }
  & Pick<OrderStatus, 'id' | 'name'>
);

export type OuterCartonParamFragmentFragment = (
  { __typename?: 'OuterCartonParam' }
  & Pick<OuterCartonParam, 'id' | 'poNumber' | 'countryOfOrigin' | 'productName' | 'janCode' | 'taxIncludedPrice' | 'quantityPerCartonText' | 'supplierName' | 'zipCode' | 'prefecture' | 'city' | 'block' | 'building'>
  & { orderProduct: (
    { __typename?: 'OrderProduct' }
    & OrderProductFragmentFragment
  ) }
);

export type PriceLabelParamFragmentFragment = (
  { __typename?: 'PriceLabelParam' }
  & Pick<PriceLabelParam, 'id' | 'janCode' | 'taxIncludedPrice'>
  & { orderProduct: (
    { __typename?: 'OrderProduct' }
    & OrderProductFragmentFragment
  ) }
);

export type ProductFragmentFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name' | 'note' | 'thumbnailPath' | 'originalPath' | 'mimeType' | 'productType' | 'priceType' | 'unitPrice' | 'unitBasicDueDate' | 'hasMinPrice' | 'minLot' | 'minPrice' | 'basicDueDate' | 'arrivalDate'>
  & { category?: Maybe<(
    { __typename?: 'ProductCategory' }
    & Pick<ProductCategory, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'ProductTag' }
    & Pick<ProductTag, 'id' | 'name'>
  )> }
);

export type ProductCategoryFragmentFragment = (
  { __typename?: 'ProductCategory' }
  & Pick<ProductCategory, 'id' | 'name' | 'viewOrder'>
);

export type ProductFileFragmentFragment = (
  { __typename?: 'ProductFile' }
  & Pick<ProductFile, 'id' | 'fileName' | 'fileSize' | 'mimeType' | 'originalPath' | 'thumbnailPath'>
);

export type ProductPriceFragmentFragment = (
  { __typename?: 'ProductPrice' }
  & Pick<ProductPrice, 'id' | 'quantity' | 'price' | 'basicDueDate'>
);

export type ProductTagFragmentFragment = (
  { __typename?: 'ProductTag' }
  & Pick<ProductTag, 'id' | 'name'>
);

export type TypesettingParamFragmentFragment = (
  { __typename?: 'TypesettingParam' }
  & Pick<TypesettingParam, 'id' | 'supplierName' | 'productName' | 'factoryProductCode' | 'janCode' | 'taxIncludedPrice' | 'trackingCode' | 'targetAgeText' | 'needleDetectionPrintFlag' | 'copyrightPatternCode' | 'countryOfOrigin' | 'recyclingSymbolCode' | 'threeCircleMarkFlag' | 'sizeName' | 'materialTableName'>
  & { orderProduct: (
    { __typename?: 'OrderProduct' }
    & OrderProductFragmentFragment
  ), typesettingParamSizes?: Maybe<Array<(
    { __typename?: 'TypesettingParamSize' }
    & Pick<TypesettingParamSize, 'id' | 'sizeComponentName' | 'sizeComponentValue'>
  )>>, typesettingParamMaterials?: Maybe<Array<(
    { __typename?: 'TypesettingParamMaterial' }
    & Pick<TypesettingParamMaterial, 'id' | 'materialHeading' | 'materialName' | 'materialValue'>
  )>>, typesettingParamWarnings?: Maybe<Array<(
    { __typename?: 'TypesettingParamWarning' }
    & Pick<TypesettingParamWarning, 'id' | 'warningHeading' | 'warning'>
  )>> }
);

export type TypesettingParamMaterialFragmentFragment = (
  { __typename?: 'TypesettingParamMaterial' }
  & Pick<TypesettingParamMaterial, 'id'>
);

export type TypesettingParamSizeFragmentFragment = (
  { __typename?: 'TypesettingParamSize' }
  & Pick<TypesettingParamSize, 'id'>
);

export type TypesettingParamWarningFragmentFragment = (
  { __typename?: 'TypesettingParamWarning' }
  & Pick<TypesettingParamWarning, 'id'>
);

export type CreateAutoTypesettingMutationVariables = Exact<{
  input: CreateAutoTypesettingInput;
}>;


export type CreateAutoTypesettingMutation = (
  { __typename?: 'Mutation' }
  & { createAutoTypesetting?: Maybe<(
    { __typename?: 'CreateAutoTypesettingPayload' }
    & { orderProduct?: Maybe<(
      { __typename?: 'OrderProduct' }
      & OrderProductFragmentFragment
    )> }
  )> }
);

export type UpdateAutoTypesettingMutationVariables = Exact<{
  input: UpdateAutoTypesettingInput;
}>;


export type UpdateAutoTypesettingMutation = (
  { __typename?: 'Mutation' }
  & { updateAutoTypesetting?: Maybe<(
    { __typename?: 'UpdateAutoTypesettingPayload' }
    & Pick<UpdateAutoTypesettingPayload, 'sonbpUri' | 'accessToken' | 'fileKey'>
    & { orderProduct?: Maybe<(
      { __typename?: 'OrderProduct' }
      & OrderProductFragmentFragment
    )> }
  )> }
);

export type CreateOneClientApplicationFlowMutationVariables = Exact<{
  input: CreateOneClientApplicationFlowInput;
}>;


export type CreateOneClientApplicationFlowMutation = (
  { __typename?: 'Mutation' }
  & { createOneClientApplicationFlow?: Maybe<(
    { __typename?: 'CreateOneClientApplicationFlowPayload' }
    & { clientApplicationFlow?: Maybe<(
      { __typename?: 'ClientApplicationFlow' }
      & ClientApplicationFlowFragmentFragment
    )> }
  )> }
);

export type RemoveOneClientApplicationFlowMutationVariables = Exact<{
  input: RemoveOneClientApplicationFlowInput;
}>;


export type RemoveOneClientApplicationFlowMutation = (
  { __typename?: 'Mutation' }
  & { removeOneClientApplicationFlow?: Maybe<(
    { __typename?: 'RemoveOneClientApplicationFlowPayload' }
    & { clientApplicationFlow?: Maybe<(
      { __typename?: 'ClientApplicationFlow' }
      & Pick<ClientApplicationFlow, 'id'>
    )> }
  )> }
);

export type UpdateOneClientApplicationFlowMutationVariables = Exact<{
  input: UpdateOneClientApplicationFlowInput;
}>;


export type UpdateOneClientApplicationFlowMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClientApplicationFlow?: Maybe<(
    { __typename?: 'UpdateOneClientApplicationFlowPayload' }
    & { clientApplicationFlow?: Maybe<(
      { __typename?: 'ClientApplicationFlow' }
      & ClientApplicationFlowFragmentFragment
    )> }
  )> }
);

export type CreateOneClientApplicationFlowLevelMutationVariables = Exact<{
  input: CreateOneClientApplicationFlowLevelInput;
}>;


export type CreateOneClientApplicationFlowLevelMutation = (
  { __typename?: 'Mutation' }
  & { createOneClientApplicationFlowLevel?: Maybe<(
    { __typename?: 'CreateOneClientApplicationFlowLevelPayload' }
    & { clientApplicationFlowLevel?: Maybe<(
      { __typename?: 'ClientApplicationFlowLevel' }
      & ClientApplicationFlowLevelFragmentFragment
    )> }
  )> }
);

export type RemoveOneClientApplicationFlowLevelMutationVariables = Exact<{
  input: RemoveOneClientApplicationFlowLevelInput;
}>;


export type RemoveOneClientApplicationFlowLevelMutation = (
  { __typename?: 'Mutation' }
  & { removeOneClientApplicationFlowLevel?: Maybe<(
    { __typename?: 'RemoveOneClientApplicationFlowLevelPayload' }
    & { clientApplicationFlowLevel?: Maybe<(
      { __typename?: 'ClientApplicationFlowLevel' }
      & Pick<ClientApplicationFlowLevel, 'id'>
    )> }
  )> }
);

export type UpdateOneClientApplicationFlowLevelMutationVariables = Exact<{
  input: UpdateOneClientApplicationFlowLevelInput;
}>;


export type UpdateOneClientApplicationFlowLevelMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClientApplicationFlowLevel?: Maybe<(
    { __typename?: 'UpdateOneClientApplicationFlowLevelPayload' }
    & { clientApplicationFlowLevel?: Maybe<(
      { __typename?: 'ClientApplicationFlowLevel' }
      & ClientApplicationFlowLevelFragmentFragment
    )> }
  )> }
);

export type CreateOneClientApplicationFlowRouteMutationVariables = Exact<{
  input: CreateOneClientApplicationFlowRouteInput;
}>;


export type CreateOneClientApplicationFlowRouteMutation = (
  { __typename?: 'Mutation' }
  & { createOneClientApplicationFlowRoute?: Maybe<(
    { __typename?: 'CreateOneClientApplicationFlowRoutePayload' }
    & { clientApplicationFlowRoute?: Maybe<(
      { __typename?: 'ClientApplicationFlowRoute' }
      & ClientApplicationFlowRouteFragmentFragment
    )> }
  )> }
);

export type RemoveOneClientApplicationFlowRouteMutationVariables = Exact<{
  input: RemoveOneClientApplicationFlowRouteInput;
}>;


export type RemoveOneClientApplicationFlowRouteMutation = (
  { __typename?: 'Mutation' }
  & { removeOneClientApplicationFlowRoute?: Maybe<(
    { __typename?: 'RemoveOneClientApplicationFlowRoutePayload' }
    & { clientApplicationFlowRoute?: Maybe<(
      { __typename?: 'ClientApplicationFlowRoute' }
      & Pick<ClientApplicationFlowRoute, 'id'>
    )> }
  )> }
);

export type UpdateOneClientApplicationFlowRouteMutationVariables = Exact<{
  input: UpdateOneClientApplicationFlowRouteInput;
}>;


export type UpdateOneClientApplicationFlowRouteMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClientApplicationFlowRoute?: Maybe<(
    { __typename?: 'UpdateOneClientApplicationFlowRoutePayload' }
    & { clientApplicationFlowRoute?: Maybe<(
      { __typename?: 'ClientApplicationFlowRoute' }
      & ClientApplicationFlowRouteFragmentFragment
    )> }
  )> }
);

export type CreateOneClientContactMutationVariables = Exact<{
  input: CreateOneClientContactInput;
}>;


export type CreateOneClientContactMutation = (
  { __typename?: 'Mutation' }
  & { createOneClientContact?: Maybe<(
    { __typename?: 'CreateOneClientContactPayload' }
    & { clientContact?: Maybe<(
      { __typename?: 'ClientContact' }
      & ClientContactFragmentFragment
    )> }
  )> }
);

export type RemoveOneClientContactMutationVariables = Exact<{
  input: RemoveOneClientContactInput;
}>;


export type RemoveOneClientContactMutation = (
  { __typename?: 'Mutation' }
  & { removeOneClientContact?: Maybe<(
    { __typename?: 'RemoveOneClientContactPayload' }
    & { clientContact?: Maybe<(
      { __typename?: 'ClientContact' }
      & ClientContactFragmentFragment
    )> }
  )> }
);

export type UpdateOneClientContactMutationVariables = Exact<{
  input: UpdateOneClientContactInput;
}>;


export type UpdateOneClientContactMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClientContact?: Maybe<(
    { __typename?: 'UpdateOneClientContactPayload' }
    & { clientContact?: Maybe<(
      { __typename?: 'ClientContact' }
      & ClientContactFragmentFragment
    )> }
  )> }
);

export type CreateOneClientDeliveryMutationVariables = Exact<{
  input: CreateOneClientDeliveryInput;
}>;


export type CreateOneClientDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { createOneClientDelivery?: Maybe<(
    { __typename?: 'CreateOneClientDeliveryPayload' }
    & { clientDelivery?: Maybe<(
      { __typename?: 'ClientDelivery' }
      & ClientDeliveryFragmentFragment
    )> }
  )> }
);

export type RemoveOneClientDeliveryMutationVariables = Exact<{
  input: RemoveOneClientDeliveryInput;
}>;


export type RemoveOneClientDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { removeOneClientDelivery?: Maybe<(
    { __typename?: 'RemoveOneClientDeliveryPayload' }
    & { clientDelivery?: Maybe<(
      { __typename?: 'ClientDelivery' }
      & ClientDeliveryFragmentFragment
    )> }
  )> }
);

export type UpdateOneClientDeliveryMutationVariables = Exact<{
  input: UpdateOneClientDeliveryInput;
}>;


export type UpdateOneClientDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClientDelivery?: Maybe<(
    { __typename?: 'UpdateOneClientDeliveryPayload' }
    & { clientDelivery?: Maybe<(
      { __typename?: 'ClientDelivery' }
      & ClientDeliveryFragmentFragment
    )> }
  )> }
);

export type RemoveOneClientStorageFileMutationVariables = Exact<{
  input: RemoveOneClientStorageFileInput;
}>;


export type RemoveOneClientStorageFileMutation = (
  { __typename?: 'Mutation' }
  & { removeOneClientStorageFile?: Maybe<(
    { __typename?: 'RemoveOneClientStorageFilePayload' }
    & { clientStorageFile?: Maybe<(
      { __typename?: 'ClientStorageFile' }
      & ClientStorageFileFragmentFragment
    )> }
  )> }
);

export type UpdateOneClientStorageFileMutationVariables = Exact<{
  input: UpdateOneClientStorageFileInput;
}>;


export type UpdateOneClientStorageFileMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClientStorageFile?: Maybe<(
    { __typename?: 'UpdateOneClientStorageFilePayload' }
    & { clientStorageFile?: Maybe<(
      { __typename?: 'ClientStorageFile' }
      & ClientStorageFileFragmentFragment
    )> }
  )> }
);

export type CreateOneClientUserMutationVariables = Exact<{
  input: CreateOneClientUserInput;
}>;


export type CreateOneClientUserMutation = (
  { __typename?: 'Mutation' }
  & { createOneClientUser?: Maybe<(
    { __typename?: 'CreateOneClientUserPayload' }
    & { clientUser?: Maybe<(
      { __typename?: 'ClientUser' }
      & ClientUserFragmentFragment
    )> }
  )> }
);

export type RemoveOneClientUserMutationVariables = Exact<{
  input: RemoveOneClientUserInput;
}>;


export type RemoveOneClientUserMutation = (
  { __typename?: 'Mutation' }
  & { removeOneClientUser?: Maybe<(
    { __typename?: 'RemoveOneClientUserPayload' }
    & { clientUser?: Maybe<(
      { __typename?: 'ClientUser' }
      & ClientUserFragmentFragment
    )> }
  )> }
);

export type UpdateOneClientUserMutationVariables = Exact<{
  input: UpdateOneClientUserInput;
}>;


export type UpdateOneClientUserMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClientUser?: Maybe<(
    { __typename?: 'UpdateOneClientUserPayload' }
    & { clientUser?: Maybe<(
      { __typename?: 'ClientUser' }
      & ClientUserFragmentFragment
    )> }
  )> }
);

export type UpdateOneInnerCartonParamMutationVariables = Exact<{
  input: UpdateOneInnerCartonParamInput;
}>;


export type UpdateOneInnerCartonParamMutation = (
  { __typename?: 'Mutation' }
  & { updateOneInnerCartonParam?: Maybe<(
    { __typename?: 'UpdateOneInnerCartonParamPayload' }
    & { innerCartonParam?: Maybe<(
      { __typename?: 'InnerCartonParam' }
      & InnerCartonParamFragmentFragment
    )> }
  )> }
);

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderInput;
}>;


export type CancelOrderMutation = (
  { __typename?: 'Mutation' }
  & { cancelOrder?: Maybe<(
    { __typename?: 'CancelOrderPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderFragmentFragment
    )> }
  )> }
);

export type CopyOneOrderMutationVariables = Exact<{
  input: CopyOneOrderInput;
}>;


export type CopyOneOrderMutation = (
  { __typename?: 'Mutation' }
  & { copyOneOrder?: Maybe<(
    { __typename?: 'CopyOneOrderPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderFragmentFragment
    )> }
  )> }
);

export type CreateOneOrderMutationVariables = Exact<{
  input: CreateOneOrderInput;
}>;


export type CreateOneOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOneOrder?: Maybe<(
    { __typename?: 'CreateOneOrderPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderFragmentFragment
    )> }
  )> }
);

export type RemoveOneOrderMutationVariables = Exact<{
  input: RemoveOneOrderInput;
}>;


export type RemoveOneOrderMutation = (
  { __typename?: 'Mutation' }
  & { removeOneOrder?: Maybe<(
    { __typename?: 'RemoveOneOrderPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    )> }
  )> }
);

export type ReorderOrderMutationVariables = Exact<{
  input: ReorderOrderInput;
}>;


export type ReorderOrderMutation = (
  { __typename?: 'Mutation' }
  & { reorderOrder?: Maybe<(
    { __typename?: 'ReorderOrderPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderFragmentFragment
    )> }
  )> }
);

export type UpdateOneOrderMutationVariables = Exact<{
  input: UpdateOneOrderInput;
}>;


export type UpdateOneOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOneOrder?: Maybe<(
    { __typename?: 'UpdateOneOrderPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderFragmentFragment
    )> }
  )> }
);

export type ApproveManyOrderApprovalRecordMutationVariables = Exact<{
  input: ApproveManyOrderApprovalRecordInput;
}>;


export type ApproveManyOrderApprovalRecordMutation = (
  { __typename?: 'Mutation' }
  & { approveManyOrderApprovalRecord?: Maybe<(
    { __typename?: 'ApproveManyOrderApprovalRecordPayload' }
    & { orderApprovalRecords?: Maybe<Array<(
      { __typename?: 'OrderApprovalRecord' }
      & OrderApprovalRecordFragmentFragment
    )>> }
  )> }
);

export type RejectOrderApprovalRecordMutationVariables = Exact<{
  input: RejectOrderApprovalRecordInput;
}>;


export type RejectOrderApprovalRecordMutation = (
  { __typename?: 'Mutation' }
  & { rejectOrderApprovalRecord?: Maybe<(
    { __typename?: 'RejectOrderApprovalRecordPayload' }
    & { orderApprovalRecord?: Maybe<(
      { __typename?: 'OrderApprovalRecord' }
      & OrderApprovalRecordFragmentFragment
    )>, orderComment?: Maybe<(
      { __typename?: 'OrderComment' }
      & OrderCommentFragmentFragment
    )> }
  )> }
);

export type CreateOneOrderCommentMutationVariables = Exact<{
  input: CreateOneOrderCommentInput;
}>;


export type CreateOneOrderCommentMutation = (
  { __typename?: 'Mutation' }
  & { createOneOrderComment?: Maybe<(
    { __typename?: 'CreateOneOrderCommentPayload' }
    & { orderComment?: Maybe<(
      { __typename?: 'OrderComment' }
      & OrderCommentFragmentFragment
    )> }
  )> }
);

export type CreateOneOrderContactMutationVariables = Exact<{
  input: CreateOneOrderContactInput;
}>;


export type CreateOneOrderContactMutation = (
  { __typename?: 'Mutation' }
  & { createOneOrderContact?: Maybe<(
    { __typename?: 'CreateOneOrderContactPayload' }
    & { orderContact?: Maybe<(
      { __typename?: 'OrderContact' }
      & OrderContactFragmentFragment
    )> }
  )> }
);

export type RemoveOneOrderContactMutationVariables = Exact<{
  input: RemoveOneOrderContactInput;
}>;


export type RemoveOneOrderContactMutation = (
  { __typename?: 'Mutation' }
  & { removeOneOrderContact?: Maybe<(
    { __typename?: 'RemoveOneOrderContactPayload' }
    & { orderContact?: Maybe<(
      { __typename?: 'OrderContact' }
      & OrderContactFragmentFragment
    )> }
  )> }
);

export type UpdateOneOrderContactMutationVariables = Exact<{
  input: UpdateOneOrderContactInput;
}>;


export type UpdateOneOrderContactMutation = (
  { __typename?: 'Mutation' }
  & { updateOneOrderContact?: Maybe<(
    { __typename?: 'UpdateOneOrderContactPayload' }
    & { orderContact?: Maybe<(
      { __typename?: 'OrderContact' }
      & OrderContactFragmentFragment
    )> }
  )> }
);

export type CreateOneOrderDeliveryMutationVariables = Exact<{
  input: CreateOneOrderDeliveryInput;
}>;


export type CreateOneOrderDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { createOneOrderDelivery?: Maybe<(
    { __typename?: 'CreateOneOrderDeliveryPayload' }
    & { orderDelivery?: Maybe<(
      { __typename?: 'OrderDelivery' }
      & OrderDeliveryFragmentFragment
    )> }
  )> }
);

export type RemoveOneOrderDeliveryMutationVariables = Exact<{
  input: RemoveOneOrderDeliveryInput;
}>;


export type RemoveOneOrderDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { removeOneOrderDelivery?: Maybe<(
    { __typename?: 'RemoveOneOrderDeliveryPayload' }
    & { orderDelivery?: Maybe<(
      { __typename?: 'OrderDelivery' }
      & OrderDeliveryFragmentFragment
    )> }
  )> }
);

export type UpdateOneOrderDeliveryMutationVariables = Exact<{
  input: UpdateOneOrderDeliveryInput;
}>;


export type UpdateOneOrderDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { updateOneOrderDelivery?: Maybe<(
    { __typename?: 'UpdateOneOrderDeliveryPayload' }
    & { orderDelivery?: Maybe<(
      { __typename?: 'OrderDelivery' }
      & OrderDeliveryFragmentFragment
    )> }
  )> }
);

export type RemoveOneOrderProductFileMutationVariables = Exact<{
  input: RemoveOneOrderProductFileInput;
}>;


export type RemoveOneOrderProductFileMutation = (
  { __typename?: 'Mutation' }
  & { removeOneOrderProductFile?: Maybe<(
    { __typename?: 'RemoveOneOrderProductFilePayload' }
    & { orderProductFile?: Maybe<(
      { __typename?: 'OrderProductFile' }
      & OrderProductFileFragmentFragment
    )> }
  )> }
);

export type UpdateOneOuterCartonParamMutationVariables = Exact<{
  input: UpdateOneOuterCartonParamInput;
}>;


export type UpdateOneOuterCartonParamMutation = (
  { __typename?: 'Mutation' }
  & { updateOneOuterCartonParam?: Maybe<(
    { __typename?: 'UpdateOneOuterCartonParamPayload' }
    & { outerCartonParam?: Maybe<(
      { __typename?: 'OuterCartonParam' }
      & OuterCartonParamFragmentFragment
    )> }
  )> }
);

export type UpdateOnePriceLabelParamMutationVariables = Exact<{
  input: UpdateOnePriceLabelParamInput;
}>;


export type UpdateOnePriceLabelParamMutation = (
  { __typename?: 'Mutation' }
  & { updateOnePriceLabelParam?: Maybe<(
    { __typename?: 'UpdateOnePriceLabelParamPayload' }
    & { priceLabelParam?: Maybe<(
      { __typename?: 'PriceLabelParam' }
      & PriceLabelParamFragmentFragment
    )> }
  )> }
);

export type UpdateOneTypesettingParamMutationVariables = Exact<{
  input: UpdateOneTypesettingParamInput;
}>;


export type UpdateOneTypesettingParamMutation = (
  { __typename?: 'Mutation' }
  & { updateOneTypesettingParam?: Maybe<(
    { __typename?: 'UpdateOneTypesettingParamPayload' }
    & { typesettingParam?: Maybe<(
      { __typename?: 'TypesettingParam' }
      & TypesettingParamFragmentFragment
    )> }
  )> }
);

export type CreateOneTypesettingParamMaterialMutationVariables = Exact<{
  input: CreateOneTypesettingParamMaterialInput;
}>;


export type CreateOneTypesettingParamMaterialMutation = (
  { __typename?: 'Mutation' }
  & { createOneTypesettingParamMaterial?: Maybe<(
    { __typename?: 'CreateOneTypesettingParamMaterialPayload' }
    & { typesettingParamMaterial?: Maybe<(
      { __typename?: 'TypesettingParamMaterial' }
      & TypesettingParamMaterialFragmentFragment
    )> }
  )> }
);

export type RemoveOneTypesettingParamMaterialMutationVariables = Exact<{
  input: RemoveOneTypesettingParamMaterialInput;
}>;


export type RemoveOneTypesettingParamMaterialMutation = (
  { __typename?: 'Mutation' }
  & { removeOneTypesettingParamMaterial?: Maybe<(
    { __typename?: 'RemoveOneTypesettingParamMaterialPayload' }
    & { typesettingParamMaterial?: Maybe<(
      { __typename?: 'TypesettingParamMaterial' }
      & TypesettingParamMaterialFragmentFragment
    )> }
  )> }
);

export type UpdateOneTypesettingParamMaterialMutationVariables = Exact<{
  input: UpdateOneTypesettingParamMaterialInput;
}>;


export type UpdateOneTypesettingParamMaterialMutation = (
  { __typename?: 'Mutation' }
  & { updateOneTypesettingParamMaterial?: Maybe<(
    { __typename?: 'UpdateOneTypesettingParamMaterialPayload' }
    & { typesettingParamMaterial?: Maybe<(
      { __typename?: 'TypesettingParamMaterial' }
      & TypesettingParamMaterialFragmentFragment
    )> }
  )> }
);

export type CreateOneTypesettingParamSizeMutationVariables = Exact<{
  input: CreateOneTypesettingParamSizeInput;
}>;


export type CreateOneTypesettingParamSizeMutation = (
  { __typename?: 'Mutation' }
  & { createOneTypesettingParamSize?: Maybe<(
    { __typename?: 'CreateOneTypesettingParamSizePayload' }
    & { typesettingParamSize?: Maybe<(
      { __typename?: 'TypesettingParamSize' }
      & TypesettingParamSizeFragmentFragment
    )> }
  )> }
);

export type RemoveOneTypesettingParamSizeMutationVariables = Exact<{
  input: RemoveOneTypesettingParamSizeInput;
}>;


export type RemoveOneTypesettingParamSizeMutation = (
  { __typename?: 'Mutation' }
  & { removeOneTypesettingParamSize?: Maybe<(
    { __typename?: 'RemoveOneTypesettingParamSizePayload' }
    & { typesettingParamSize?: Maybe<(
      { __typename?: 'TypesettingParamSize' }
      & TypesettingParamSizeFragmentFragment
    )> }
  )> }
);

export type UpdateOneTypesettingParamSizeMutationVariables = Exact<{
  input: UpdateOneTypesettingParamSizeInput;
}>;


export type UpdateOneTypesettingParamSizeMutation = (
  { __typename?: 'Mutation' }
  & { updateOneTypesettingParamSize?: Maybe<(
    { __typename?: 'UpdateOneTypesettingParamSizePayload' }
    & { typesettingParamSize?: Maybe<(
      { __typename?: 'TypesettingParamSize' }
      & TypesettingParamSizeFragmentFragment
    )> }
  )> }
);

export type CreateOneTypesettingParamWarningMutationVariables = Exact<{
  input: CreateOneTypesettingParamWarningInput;
}>;


export type CreateOneTypesettingParamWarningMutation = (
  { __typename?: 'Mutation' }
  & { createOneTypesettingParamWarning?: Maybe<(
    { __typename?: 'CreateOneTypesettingParamWarningPayload' }
    & { typesettingParamWarning?: Maybe<(
      { __typename?: 'TypesettingParamWarning' }
      & TypesettingParamWarningFragmentFragment
    )> }
  )> }
);

export type RemoveOneTypesettingParamWarningMutationVariables = Exact<{
  input: RemoveOneTypesettingParamWarningInput;
}>;


export type RemoveOneTypesettingParamWarningMutation = (
  { __typename?: 'Mutation' }
  & { removeOneTypesettingParamWarning?: Maybe<(
    { __typename?: 'RemoveOneTypesettingParamWarningPayload' }
    & { typesettingParamWarning?: Maybe<(
      { __typename?: 'TypesettingParamWarning' }
      & TypesettingParamWarningFragmentFragment
    )> }
  )> }
);

export type UpdateOneTypesettingParamWarningMutationVariables = Exact<{
  input: UpdateOneTypesettingParamWarningInput;
}>;


export type UpdateOneTypesettingParamWarningMutation = (
  { __typename?: 'Mutation' }
  & { updateOneTypesettingParamWarning?: Maybe<(
    { __typename?: 'UpdateOneTypesettingParamWarningPayload' }
    & { typesettingParamWarning?: Maybe<(
      { __typename?: 'TypesettingParamWarning' }
      & TypesettingParamWarningFragmentFragment
    )> }
  )> }
);

export type GetManyInnerCartonParamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyInnerCartonParamQuery = (
  { __typename?: 'Query' }
  & { innerCartonParams: Array<(
    { __typename?: 'InnerCartonParam' }
    & InnerCartonParamFragmentFragment
  )> }
);

export type GetOneInnerCartonParamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneInnerCartonParamQuery = (
  { __typename?: 'Query' }
  & { innerCartonParam: (
    { __typename?: 'InnerCartonParam' }
    & InnerCartonParamFragmentFragment
  ) }
);

export type GetManyClientApplicationFlowQueryVariables = Exact<{
  clientUserGroupId: Scalars['ID'];
}>;


export type GetManyClientApplicationFlowQuery = (
  { __typename?: 'Query' }
  & { clientApplicationFlows: Array<(
    { __typename?: 'ClientApplicationFlow' }
    & { clientUserGroup: (
      { __typename?: 'ClientUserGroup' }
      & ClientUserGroupFragmentFragment
    ), routes: Array<(
      { __typename?: 'ClientApplicationFlowRoute' }
      & { levels: Array<(
        { __typename?: 'ClientApplicationFlowLevel' }
        & ClientApplicationFlowLevelFragmentFragment
      )> }
      & ClientApplicationFlowRouteFragmentFragment
    )> }
    & ClientApplicationFlowFragmentFragment
  )> }
);

export type GetOneClientApplicationFlowQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneClientApplicationFlowQuery = (
  { __typename?: 'Query' }
  & { clientApplicationFlow: (
    { __typename?: 'ClientApplicationFlow' }
    & { clientUserGroup: (
      { __typename?: 'ClientUserGroup' }
      & { members: Array<(
        { __typename?: 'ClientUser' }
        & ClientUserFragmentFragment
      )> }
      & ClientUserGroupFragmentFragment
    ), routes: Array<(
      { __typename?: 'ClientApplicationFlowRoute' }
      & { levels: Array<(
        { __typename?: 'ClientApplicationFlowLevel' }
        & ClientApplicationFlowLevelFragmentFragment
      )> }
      & ClientApplicationFlowRouteFragmentFragment
    )> }
    & ClientApplicationFlowFragmentFragment
  ) }
);

export type GetManyClientApplicationFlowLevelQueryVariables = Exact<{
  routeId: Scalars['ID'];
}>;


export type GetManyClientApplicationFlowLevelQuery = (
  { __typename?: 'Query' }
  & { clientApplicationFlowLevels: Array<(
    { __typename?: 'ClientApplicationFlowLevel' }
    & ClientApplicationFlowLevelFragmentFragment
  )> }
);

export type GetOneClientApplicationFlowLevelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneClientApplicationFlowLevelQuery = (
  { __typename?: 'Query' }
  & { clientApplicationFlowLevel: (
    { __typename?: 'ClientApplicationFlowLevel' }
    & ClientApplicationFlowLevelFragmentFragment
  ) }
);

export type GetManyClientApplicationFlowRouteQueryVariables = Exact<{
  flowId: Scalars['ID'];
}>;


export type GetManyClientApplicationFlowRouteQuery = (
  { __typename?: 'Query' }
  & { clientApplicationFlowRoutes: Array<(
    { __typename?: 'ClientApplicationFlowRoute' }
    & ClientApplicationFlowRouteFragmentFragment
  )> }
);

export type GetOneClientApplicationFlowRouteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneClientApplicationFlowRouteQuery = (
  { __typename?: 'Query' }
  & { clientApplicationFlowRoute: (
    { __typename?: 'ClientApplicationFlowRoute' }
    & ClientApplicationFlowRouteFragmentFragment
  ) }
);

export type GetManyClientContactQueryVariables = Exact<{
  clientUserGroupId?: Maybe<Scalars['ID']>;
}>;


export type GetManyClientContactQuery = (
  { __typename?: 'Query' }
  & { clientContacts: Array<(
    { __typename?: 'ClientContact' }
    & ClientContactFragmentFragment
  )> }
);

export type GetManyClientDeliveryQueryVariables = Exact<{
  clientUserGroupId?: Maybe<Scalars['ID']>;
}>;


export type GetManyClientDeliveryQuery = (
  { __typename?: 'Query' }
  & { clientDeliveries: Array<(
    { __typename?: 'ClientDelivery' }
    & ClientDeliveryFragmentFragment
  )> }
);

export type GetManyClientStorageFileQueryVariables = Exact<{
  clientUserGroupId?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetManyClientStorageFileQuery = (
  { __typename?: 'Query' }
  & { clientStorageFiles: Array<(
    { __typename?: 'ClientStorageFile' }
    & ClientStorageFileFragmentFragment
  )> }
);

export type GetOneClientStorageFileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneClientStorageFileQuery = (
  { __typename?: 'Query' }
  & { clientStorageFile: (
    { __typename?: 'ClientStorageFile' }
    & ClientStorageFileFragmentFragment
  ) }
);

export type GetOneClientStorageSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOneClientStorageSummaryQuery = (
  { __typename?: 'Query' }
  & { clientStorageSummary: (
    { __typename?: 'ClientStorageSummary' }
    & ClientStorageSummaryFragmentFragment
  ) }
);

export type GetManyClientUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyClientUserQuery = (
  { __typename?: 'Query' }
  & { clientUsers: Array<(
    { __typename?: 'ClientUser' }
    & ClientUserFragmentFragment
  )> }
);

export type GetOneClientUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneClientUserQuery = (
  { __typename?: 'Query' }
  & { clientUser: (
    { __typename?: 'ClientUser' }
    & { userGroups: Array<(
      { __typename?: 'ClientUserGroup' }
      & ClientUserGroupFragmentFragment
    )> }
    & ClientUserFragmentFragment
  ) }
);

export type GetManyClientUserGroupQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyClientUserGroupQuery = (
  { __typename?: 'Query' }
  & { clientUserGroups: Array<(
    { __typename?: 'ClientUserGroup' }
    & { members: Array<(
      { __typename?: 'ClientUser' }
      & ClientUserFragmentFragment
    )>, children: Array<(
      { __typename?: 'ClientUserGroup' }
      & { members: Array<(
        { __typename?: 'ClientUser' }
        & ClientUserFragmentFragment
      )> }
      & ClientUserGroupFragmentFragment
    )> }
    & ClientUserGroupFragmentFragment
  )> }
);

export type GetOneClientUserGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneClientUserGroupQuery = (
  { __typename?: 'Query' }
  & { clientUserGroup: (
    { __typename?: 'ClientUserGroup' }
    & { products: Array<(
      { __typename?: 'Product' }
      & ProductFragmentFragment
    )>, members: Array<(
      { __typename?: 'ClientUser' }
      & ClientUserFragmentFragment
    )>, defaultContact?: Maybe<(
      { __typename?: 'ClientContact' }
      & ClientContactFragmentFragment
    )>, defaultDelivery?: Maybe<(
      { __typename?: 'ClientDelivery' }
      & ClientDeliveryFragmentFragment
    )> }
    & ClientUserGroupFragmentFragment
  ) }
);

export type GetManyHolidayQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyHolidayQuery = (
  { __typename?: 'Query' }
  & { holidays: Array<(
    { __typename?: 'Holiday' }
    & HolidayFragmentFragment
  )> }
);

export type GetManyNotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyNotificationQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & NotificationFragmentFragment
  )> }
);

export type GetOneNotificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneNotificationQuery = (
  { __typename?: 'Query' }
  & { notification: (
    { __typename?: 'Notification' }
    & NotificationFragmentFragment
  ) }
);

export type GetAmountOrderQueryVariables = Exact<{
  clientUserId?: Maybe<Scalars['ID']>;
  draftOnly?: Maybe<Scalars['Boolean']>;
  orderedFrom?: Maybe<Scalars['ISO8601DateTime']>;
  orderedTo?: Maybe<Scalars['ISO8601DateTime']>;
  orderNo?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  orderStatusId?: Maybe<Scalars['ID']>;
  productCategoryId?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAmountOrderQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ordersAmount'>
);

export type GetManyOrderQueryVariables = Exact<{
  clientUserId?: Maybe<Scalars['ID']>;
  draftOnly?: Maybe<Scalars['Boolean']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  orderedFrom?: Maybe<Scalars['ISO8601DateTime']>;
  orderedTo?: Maybe<Scalars['ISO8601DateTime']>;
  orderNo?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  orderStatusId?: Maybe<Scalars['ID']>;
  productCategoryId?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetManyOrderQuery = (
  { __typename?: 'Query' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & { children: Array<(
      { __typename?: 'Order' }
      & OrderFragmentFragment
    )> }
    & OrderFragmentFragment
  )> }
);

export type GetOneOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneOrderQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'Order' }
    & { children: Array<(
      { __typename?: 'Order' }
      & OrderFragmentFragment
    )> }
    & OrderFragmentFragment
  ) }
);

export type GetTotalOrderQueryVariables = Exact<{
  clientUserId?: Maybe<Scalars['ID']>;
  draftOnly?: Maybe<Scalars['Boolean']>;
  orderedFrom?: Maybe<Scalars['ISO8601DateTime']>;
  orderedTo?: Maybe<Scalars['ISO8601DateTime']>;
  orderNo?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  orderStatusId?: Maybe<Scalars['ID']>;
  productCategoryId?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetTotalOrderQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ordersTotal'>
);

export type GetManyOrderApprovalRecordQueryVariables = Exact<{
  current?: Maybe<Scalars['Boolean']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
}>;


export type GetManyOrderApprovalRecordQuery = (
  { __typename?: 'Query' }
  & { orderApprovalRecords: Array<(
    { __typename?: 'OrderApprovalRecord' }
    & { children: Array<(
      { __typename?: 'OrderApprovalRecord' }
      & OrderApprovalRecordFragmentFragment
    )> }
    & OrderApprovalRecordFragmentFragment
  )> }
);

export type GetManyOrderCommentQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type GetManyOrderCommentQuery = (
  { __typename?: 'Query' }
  & { orderComments: Array<(
    { __typename?: 'OrderComment' }
    & OrderCommentFragmentFragment
  )> }
);

export type GetManyOrderProductFileQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type GetManyOrderProductFileQuery = (
  { __typename?: 'Query' }
  & { orderProductFiles: Array<(
    { __typename?: 'OrderProductFile' }
    & OrderProductFileFragmentFragment
  )> }
);

export type GetManyOrderStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyOrderStatusQuery = (
  { __typename?: 'Query' }
  & { orderStatuses: Array<(
    { __typename?: 'OrderStatus' }
    & OrderStatusFragmentFragment
  )> }
);

export type GetManyOuterCartonParamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyOuterCartonParamQuery = (
  { __typename?: 'Query' }
  & { outerCartonParams: Array<(
    { __typename?: 'OuterCartonParam' }
    & OuterCartonParamFragmentFragment
  )> }
);

export type GetOneOuterCartonParamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneOuterCartonParamQuery = (
  { __typename?: 'Query' }
  & { outerCartonParam: (
    { __typename?: 'OuterCartonParam' }
    & OuterCartonParamFragmentFragment
  ) }
);

export type GetManyPriceLabelParamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyPriceLabelParamQuery = (
  { __typename?: 'Query' }
  & { priceLabelParams: Array<(
    { __typename?: 'PriceLabelParam' }
    & PriceLabelParamFragmentFragment
  )> }
);

export type GetOnePriceLabelParamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOnePriceLabelParamQuery = (
  { __typename?: 'Query' }
  & { priceLabelParam: (
    { __typename?: 'PriceLabelParam' }
    & PriceLabelParamFragmentFragment
  ) }
);

export type GetManyProductQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  clientUserGroup?: Maybe<Scalars['ID']>;
  productTagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetManyProductQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & { files: Array<(
      { __typename?: 'ProductFile' }
      & ProductFileFragmentFragment
    )> }
    & ProductFragmentFragment
  )> }
);

export type GetOneProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneProductQuery = (
  { __typename?: 'Query' }
  & { product: (
    { __typename?: 'Product' }
    & { files: Array<(
      { __typename?: 'ProductFile' }
      & ProductFileFragmentFragment
    )>, prices: Array<(
      { __typename?: 'ProductPrice' }
      & ProductPriceFragmentFragment
    )> }
    & ProductFragmentFragment
  ) }
);

export type GetManyProductCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyProductCategoryQuery = (
  { __typename?: 'Query' }
  & { productCategories: Array<(
    { __typename?: 'ProductCategory' }
    & ProductCategoryFragmentFragment
  )> }
);

export type GetOneProductPriceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneProductPriceQuery = (
  { __typename?: 'Query' }
  & { productPrice: (
    { __typename?: 'ProductPrice' }
    & { product: (
      { __typename?: 'Product' }
      & ProductFragmentFragment
    ) }
    & ProductPriceFragmentFragment
  ) }
);

export type GetManyProductTagQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyProductTagQuery = (
  { __typename?: 'Query' }
  & { productTags: Array<(
    { __typename?: 'ProductTag' }
    & ProductTagFragmentFragment
  )> }
);

export type GetManyTypesettingParamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyTypesettingParamQuery = (
  { __typename?: 'Query' }
  & { typesettingParams: Array<(
    { __typename?: 'TypesettingParam' }
    & TypesettingParamFragmentFragment
  )> }
);

export type GetOneTypesettingParamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOneTypesettingParamQuery = (
  { __typename?: 'Query' }
  & { typesettingParam: (
    { __typename?: 'TypesettingParam' }
    & TypesettingParamFragmentFragment
  ) }
);
